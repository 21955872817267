import ReactInputMask from 'react-input-mask';

import styled, { css } from 'styled-components';

import * as TextFieldPrimitive from '@components/TextField/styles';

export const MonthCalendarBase = styled.div<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

export const MonthCalendar = styled(ReactInputMask)`
  ${({ theme }) => TextFieldPrimitive.TextFieldCss(theme)}
  background-image: url('/assets/images/calendar-clear.svg');
  background-repeat: no-repeat;
  background-position: right 16px center;
`;

export const MonthCalendarLabel = TextFieldPrimitive.TextFieldLabel;

export const MonthCalendarControl = TextFieldPrimitive.TextFieldControl;

export const MontCalendarHelperText = TextFieldPrimitive.TextFieldHelperText;

export const MonthCalendarErrorMessage =
  TextFieldPrimitive.TextFieldErrorMessage;

export const Header = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.brand.primary};
    border-radius: ${theme.border.radius.mediumRounded}
      ${theme.border.radius.mediumRounded} 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & span {
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.brand.white};
    }
  `}
`;
