import styled, { css, DefaultTheme } from 'styled-components';

import { ButtonProps } from '.';

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    height: 32px;
    font-size: ${theme.font.sizes.small};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.medium};
  `,
  medium: (theme: DefaultTheme) => css`
    height: 40px;
    font-size: ${theme.font.sizes.medium};
    padding: ${theme.spacings.xsmall} ${theme.spacings.medium};
  `,
  large: (theme: DefaultTheme) => css`
    height: 48px;
    font-size: ${theme.font.sizes.h6};
    padding: 12px ${theme.spacings.large};
  `,
  fullWidth: () => css`
    width: 100%;
  `,

  floatingSizeModifiers: {
    small: (theme: DefaultTheme) => css`
      height: ${theme.spacings.large};
      width: ${theme.spacings.large};

      svg {
        width: ${theme.spacings.medium};
        height: ${theme.spacings.medium};
      }
    `,
    medium: (theme: DefaultTheme) => css`
      height: ${theme.spacings.xlarge};
      width: ${theme.spacings.xlarge};

      svg {
        width: ${theme.spacings.large};
        height: ${theme.spacings.large};
      }
    `,
    large: (theme: DefaultTheme) => css`
      height: ${theme.spacings.xxlarge};
      width: ${theme.spacings.xxlarge};

      svg {
        width: ${theme.spacings.xlarge};
        height: ${theme.spacings.xlarge};
      }
    `
  },

  disabled: (theme: DefaultTheme, primary: boolean) => css`
    ${primary
      ? css`
          &:disabled {
            cursor: not-allowed;
            color: ${theme.colors.text.grayscaleLight1};
            background: ${theme.colors.text.grayscaleLight3};
          }
        `
      : css`
          cursor: not-allowed;
          color: ${theme.colors.text.grayscaleLight1};
          border: 1px solid ${theme.colors.text.grayscaleLight3};

          &:hover {
            background: transparent;
          }
        `}
  `,
  loading: (theme: DefaultTheme, primary: boolean) => css`
    ${primary
      ? css`
          cursor: not-allowed;
          &:disabled {
            color: transparent;
            background: ${theme.colors.brand.primary};
          }
        `
      : css`
          color: transparent;
          background: transparent;
          border: 1px solid ${theme.colors.brand.primaryLight2};

          &:hover {
            background: transparent;
          }
        `}
  `,
  primary: (theme: DefaultTheme) => css`
    background: ${theme.colors.brand.primary};
    border: 0;

    color: ${theme.colors.brand.white};

    &:hover {
      background: ${theme.colors.brand.primaryLight1};
    }
  `,
  secondary: (theme: DefaultTheme) => css`
    background: transparent;
    border: 1px solid ${theme.colors.brand.primary};

    color: ${theme.colors.brand.primary};

    &:hover {
      background: ${theme.colors.brand.primaryLight3};
    }
  `
};

const floatingButtonModifiers = {
  filter: (
    theme: DefaultTheme,
    primary: boolean,
    size: 'small' | 'medium' | 'large'
  ) => css`
    position: fixed;
    right: 0;
    bottom: 0;

    outline: none;
    border-radius: ${theme.border.radius.circle};

    margin: ${theme.spacings.xsmall};

    ${!!size && wrapperModifiers.floatingSizeModifiers[size](theme)}

    ${primary
      ? wrapperModifiers.primary(theme)
      : wrapperModifiers.secondary(theme)};

    z-index: ${theme.layers.menu};
  `
};

export const Wrapper = styled.button<
  Pick<
    ButtonProps,
    'size' | 'fullWidth' | 'primary' | 'isLoading' | 'floatingActionButton'
  >
>`
  ${({
    theme,
    size,
    fullWidth,
    disabled,
    primary,
    isLoading,
    floatingActionButton
  }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    max-width: 206px;

    cursor: pointer;

    border-radius: ${theme.border.radius.softlyRounded};
    padding: ${theme.spacings.xxsmall};

    text-decoration: none;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    line-height: ${theme.font.sizes.h4};
    position: relative;

    & > span {
      display: inherit;
      white-space: normal;
      padding: 0;
    }

    ${primary
      ? wrapperModifiers.primary(theme)
      : wrapperModifiers.secondary(theme)};
    ${!!size && wrapperModifiers[size](theme)};
    ${!!fullWidth && wrapperModifiers.fullWidth()};
    ${!!floatingActionButton &&
    floatingButtonModifiers.filter(theme, primary!, size!)};
    ${disabled && wrapperModifiers.disabled(theme, primary!)};
    ${isLoading && wrapperModifiers.loading(theme, primary!)};
  `}
`;

export const ButtonLoading = styled.span`
  padding: 0;
  position: absolute;
  visibility: visible;
  display: flex;
  left: 50%;
  transform: translate(-50%);
`;
