import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '@components/Button';
import { MonthCalendar } from '@components/MonthCalendar';
import { TextField } from '@components/TextField';
import { useFilters } from '@providers/FiltersProvider';
import { Filter } from '@styled-icons/ionicons-outline/Filter';

import * as S from './styles';

export const FiltersModal = () => {
  const {
    date,
    entities,
    onChangeDate,
    onChangeSearch,
    onClearFilters,
    onClearSearch,
    onClientSideSearch,
    onServerSideSearch,
    searchValue
  } = useFilters();

  const [show, setShow] = useState(false);

  const handleChangePeriod = (newDate: Date) => {
    onChangeDate(newDate);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(event.target.value);
  };

  const handleApplyFilters = async () => {
    setShow(!show);

    await onServerSideSearch(date, entities);
    onClientSideSearch(searchValue);
  };

  const handleResetFilters = () => {
    onClearFilters();
    onServerSideSearch(new Date(), entities);
  };

  return (
    <>
      <Button
        onClick={() => setShow(!show)}
        floatingActionButton={true}
        aria-label="Open Modal"
      >
        <Filter />
      </Button>

      <S.WrapperModal
        show={show}
        onHide={() => setShow(!show)}
        centered
        fullscreen={true}
        backdropClassName="modal-filter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar por:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            type="search"
            label="Busque pelo nome ou extensão do arquivo"
            name="search"
            className="search"
            value={searchValue}
            onChange={handleChangeSearch}
            clearSearch={onClearSearch}
            fullWidth
          />

          <MonthCalendar
            name="period"
            label="Período"
            startDate={date}
            value={date}
            onChange={handleChangePeriod}
            fullWidth
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size="small" primary={false} onClick={handleResetFilters}>
            Limpar filtros
          </Button>
          <Button size="small" onClick={handleApplyFilters}>
            Aplicar filtros
          </Button>
        </Modal.Footer>
      </S.WrapperModal>
      {/* <S.ModalWrapperStyles /> */}
    </>
  );
};
