import {
  InformationCircle as InformationIcon,
  AlertCircle as ErrorIcon,
  CheckmarkCircle as SuccessIcon,
  Warning as WarningIcon
} from '@styled-icons/ionicons-outline';

import * as S from './styles';

export type AlertProps = {
  children: string | React.ReactNode;
  severity?: 'info' | 'warning' | 'error' | 'success';
  variant?: 'filled' | 'standard';
  justify?: 'start' | 'center' | 'end';
  fullWidth?: boolean;
};

const severityIcon = {
  info: InformationIcon,
  error: ErrorIcon,
  success: SuccessIcon,
  warning: WarningIcon
};

export const Alert = ({
  children,
  severity = 'info',
  variant = 'standard',
  justify = 'start',
  fullWidth = false
}: AlertProps) => {
  const Icon = severityIcon[severity!];

  return (
    <S.AlertBody
      role="alert"
      severity={severity}
      variant={variant}
      justify={justify}
      fullWidth={fullWidth}
    >
      <Icon />
      {children}
    </S.AlertBody>
  );
};
