import styled from 'styled-components';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const Content = styled(TooltipPrimitive.Content)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.text.grayscaleLight1};
  border-radius: ${({ theme }) => theme.border.radius.softlyRounded};
  padding: 0 16px;
  height: 40px;
`;

export const Trigger = styled(TooltipPrimitive.Trigger)`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.brand.white};
  font-size: ${({ theme }) => theme.font.sizes.small};
`;

export const Box = styled.div`
  color: ${({ theme }) => theme.colors.text.grayscaleLight1};
`;
