import styled, { css } from 'styled-components';

import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown as ChevronDownIcon } from '@styled-icons/ionicons-outline';

export const AccordionHeader = styled(AccordionPrimitive.Header)`
  all: unset;
  display: flex;
`;

export const AccordionTrigger = styled(AccordionPrimitive.Trigger)`
  ${({ theme }) => css`
    all: unset;
    font-family: inherit;
    background-color: ${theme.colors.text.grayscaleLight6};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    line-height: 24px;
    color: ${theme.colors.text.grayscale};
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacings.large};
    padding: 12px ${theme.spacings.small};
    &[data-state='closed'] {
      border-radius: ${theme.border.radius.softlyRounded};
    }
    &[data-state='open'] {
      border-top-left-radius: ${theme.border.radius.softlyRounded};
      border-top-right-radius: ${theme.border.radius.softlyRounded};
    }
  `}
`;

export const AccordionIcon = styled(ChevronDownIcon)`
  ${({ theme }) => css`
    width: ${theme.font.sizes.medium};
    height: ${theme.font.sizes.medium};
    color: ${theme.colors.brand.secondaryLight1};
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    [data-state='open'] & {
      transform: rotate(180deg);
    }
  `}
`;
