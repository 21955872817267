import { useMemo, Fragment } from 'react';

import { filter, get, isEmpty, map } from 'lodash';

import { AccordionContent } from './AccordionContent';
import { AccordionTrigger } from './AccordionTrigger';
import * as S from './styles';

type AccordionItem = {
  id: string;
  title: string;
  subtitle?: string;
  content: string | React.ReactNode;
  open?: boolean;
};

type AccordionProps = {
  items: AccordionItem[];
  type: 'single' | 'multiple';
};

export const Accordion = ({ items, type }: AccordionProps) => {
  const defaultValue = useMemo(() => {
    if (type === 'single') {
      const firstOpen = filter(items, { open: true });
      return !isEmpty(firstOpen) ? get(firstOpen, 'id') : get(items, '[0].id');
    } else {
      const opened = filter(items, { open: true });
      return map(opened, 'id');
    }
  }, [type, items]);

  return (
    <S.Accordion type={type} defaultValue={defaultValue}>
      {map(items, (item) => (
        <Fragment key={item.id}>
          <S.AccordionItem value={item.id}>
            <AccordionTrigger>{item.title}</AccordionTrigger>
            <AccordionContent>{item.content}</AccordionContent>
          </S.AccordionItem>
          <S.AccordionDivider gradientTheme="menu" />
        </Fragment>
      ))}
    </S.Accordion>
  );
};
