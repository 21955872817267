import { components, ControlProps } from 'react-select';

import type { OptionType } from '../';
import * as S from '../styles';

export const Control = ({
  children,
  ...props
}: ControlProps<OptionType, true>) => {
  const {
    isFocused,
    hasValue,
    selectProps: {
      name,
      label,
      isRequired,
      helperText,
      invalidText,
      isDisabled
    }
  } = props;

  const requiredText = isRequired === true ? ' *' : '';
  const isInvalid = !!invalidText;

  return (
    <>
      <components.Control {...props}>{children}</components.Control>

      <S.MultiAutocompleteInputLabel
        htmlFor={name}
        isFocused={isFocused}
        hasValue={hasValue}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
      >
        {label}
        {requiredText}
      </S.MultiAutocompleteInputLabel>

      {helperText && !isInvalid && (
        <S.MultiAutocompleteInputHelperText role="note">
          {helperText}
        </S.MultiAutocompleteInputHelperText>
      )}

      {isInvalid && (
        <S.MultiAutocompleteInputErrorMessage role="alert">
          {invalidText}
        </S.MultiAutocompleteInputErrorMessage>
      )}
    </>
  );
};
