import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react';

import { compact, get, isEmpty, map } from 'lodash';
import compactFP from 'lodash/fp/compact';
import filterFP from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import mapFP from 'lodash/fp/map';
import { matchSorter } from 'match-sorter';

import { api } from '@services/http';
import { parseMonthNumber, parseYearNumber } from '@utils/parseDateFormat';

export type EntitiesProps = {
  label: string;
  value: string;
};

type File = {
  entityId: string;
  entityName: string;
  extension: string;
  id: string;
  lastModified: Date;
  name: string;
};

type FileData = {
  id: string;
  title: string;
  files: File[];
};

export type FilesProps = {
  loading: boolean;
  original: FileData[];
  data: FileData[];
  message?: string;
};

export type FiltersProviderProps = {
  children: ReactNode;
};

export type FiltersContextData = {
  onChangeSearch: (text: string) => void;
  date: Date;
  entitiesOptions: EntitiesProps[];
  entities: EntitiesProps[];
  searchValue: string;
  loadingEntities: boolean;
  onClearFilters: () => void;
  onClearSearch: () => void;
  onClearDate: () => void;
  onChangeDate: (date: Date) => void;
  onChangeEntitiesOptions: (entities: EntitiesProps[]) => void;
  onChangeEntities: (entities: EntitiesProps[]) => void;
  onChangeEntitiesLoading: (loading: boolean) => void;
  onChangeFilesLoading: (loading: boolean, message?: string) => void;
  onServerSideSearch: (
    searchData: Date,
    searchEntities: EntitiesProps[]
  ) => Promise<void>;
  onClientSideSearch: (searchText: string) => void;
  files: FilesProps;
};

const CURRENT_DATE = new Date();

const INITIAL_DATE =
  CURRENT_DATE.getDate() > 10
    ? CURRENT_DATE
    : new Date(
        CURRENT_DATE.getMonth() === 0
          ? CURRENT_DATE.getFullYear() - 1
          : CURRENT_DATE.getFullYear(),
        CURRENT_DATE.getMonth() === 0 ? 11 : CURRENT_DATE.getMonth() - 1,
        CURRENT_DATE.getDate(),
        12,
        0,
        0
      );

export const FiltersContext = createContext({} as FiltersContextData);

const FiltersProvider = ({ children }: FiltersProviderProps) => {
  //Date context data
  const [date, setDate] = useState(INITIAL_DATE);

  // Search context data
  const [searchValue, setSearchValue] = useState('');

  // Entities Options context data
  const [entitiesOptions, setEntitiesOptions] = useState<EntitiesProps[]>([]);

  // Entities context data
  const [entities, setEntities] = useState<EntitiesProps[]>([]);
  const [loadingEntities, setLoadingEntities] = useState(true);

  const [files, setFiles] = useState<FilesProps>({
    loading: true,
    original: [],
    data: [],
    message: ''
  });

  const onChangeDate = (newDate: Date) => setDate(newDate);

  const onChangeSearch = (text: string) => setSearchValue(text);

  const onClearFilters = () => {
    setDate(INITIAL_DATE);
    setSearchValue('');
  };

  const onClearSearch = () => setSearchValue('');

  const onClearDate = () => setDate(INITIAL_DATE);

  const onChangeEntitiesOptions = (newEntities: EntitiesProps[]) =>
    setEntitiesOptions(newEntities);

  const onChangeEntities = (newEntities: EntitiesProps[]) =>
    setEntities(newEntities);

  const onChangeEntitiesLoading = (loading: boolean) =>
    setLoadingEntities(loading);

  const onChangeFilesLoading = (loading: boolean, message?: string) => {
    const fileMessage = isEmpty(message) ? '' : message;

    setFiles((prevState) => ({
      ...prevState,
      loading,
      message: fileMessage
    }));
  };

  const onServerSideSearch = async (
    searchData: Date,
    searchEntities: EntitiesProps[]
  ) => {
    setFiles({
      loading: true,
      original: [],
      data: [],
      message: ''
    });

    const month = parseMonthNumber(searchData);
    const year = parseYearNumber(searchData);

    const valuesToFilter = flow(
      compactFP,
      filterFP(({ value }: EntitiesProps) => value !== '*'),
      mapFP(({ value }) => value)
    )(searchEntities);

    try {
      const { data } = await api.post<FileData[]>(
        `/arquivos/${month}/${year}`,
        valuesToFilter
      );

      setFiles({
        loading: false,
        original: data,
        data: data,
        message: ''
      });

      if (searchValue) {
        onClientSideSearch(searchValue);
      }
    } catch (error) {
      const message = get(error, 'data.message');
      setFiles({
        loading: false,
        original: [],
        data: [],
        message: message || 'Ocorreu um erro ao realizar a pesquisa'
      });
    }
  };

  const onClientSideSearch = useCallback(
    (searchText: string) => {
      const filteredData =
        !isEmpty(files.original) && !isEmpty(searchText)
          ? compact(
              map(files.original, (item) => {
                const filteredFiles = matchSorter(item.files, searchText, {
                  keys: ['name'],
                  threshold: matchSorter.rankings.CONTAINS
                });

                if (!isEmpty(filteredFiles)) {
                  return {
                    ...item,
                    files: filteredFiles
                  };
                }

                return null;
              })
            )
          : files.original;

      setFiles((prevState) => ({
        ...prevState,
        data: filteredData,
        loading: false,
        message: ''
      }));
    },
    [files.original]
  );

  return (
    <FiltersContext.Provider
      value={{
        date,
        entities,
        entitiesOptions,
        files,
        loadingEntities,
        onChangeDate,
        onChangeEntities,
        onChangeEntitiesLoading,
        onChangeFilesLoading,
        onChangeEntitiesOptions,
        onChangeSearch,
        onClearDate,
        onClearFilters,
        onClearSearch,
        onClientSideSearch,
        onServerSideSearch,
        searchValue
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

const useFilters = () => useContext(FiltersContext);

export { FiltersProvider, useFilters };
