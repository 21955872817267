import styled, { css, keyframes } from 'styled-components';

import { LinearLoaderProps } from '.';

export const Loader = styled.span<LinearLoaderProps>`
  ${({ theme, indeterminate }) => css`
    width: 100%;
    height: ${theme.spacings.xxsmall};
    display: inherit;
    position: absolute;
    background: ${theme.colors.brand.tertiaryLight2};
    overflow: hidden;
    z-index: 200;

    &::after {
      content: '';
      width: 192px;
      height: ${theme.spacings.xxsmall};
      background: ${theme.colors.brand.tertiary};
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      animation: ${indeterminate === true
        ? css`
            ${linearIndeterminate} 1.5s linear infinite
          `
        : css`
            ${linearDeterminate} 5s linear infinite
          `};
    }
  `}
`;

const linearDeterminate = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const linearIndeterminate = keyframes`
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
`;
