import { createContext, useEffect, ReactNode } from 'react';

import get from 'lodash/get';

import { useAuth } from '@providers/AuthProvider';

const ScriptsContext = createContext(null);

type ScriptsProviderProps = {
  children: ReactNode;
};

export const ScriptsProvider = ({ children }: ScriptsProviderProps) => {
  const { currentUser } = useAuth();
  const email = get(currentUser, 'profile.email', '');

  useEffect(() => {
    function validateScripts() {
      const isProd = window.location.hostname === 'financeiro.clear.sale';
      const dominionClearSale =
        email.includes('@clear.sale') || email.includes('@clearsale.com.br');

      const hasScriptLO = !!document.querySelector('script#luckyorange');
      const hasScriptGA = !!document.querySelector('script#googleanalytics');

      if (isProd && !dominionClearSale && !hasScriptLO) {
        const script = document.createElement('script');
        script.setAttribute('id', 'luckyorange');
        script.setAttribute('async', '');
        script.setAttribute('defer', '');
        script.setAttribute(
          'src',
          `https://tools.luckyorange.com/core/lo.js?site-id=${process.env.REACT_APP_LUCKY_ORANGE_ID}`
        );
        document.head.appendChild(script);
      }

      if (isProd && !dominionClearSale && !hasScriptGA) {
        const script1 = document.createElement('script');
        script1.setAttribute('id', 'googleanalytics');
        script1.setAttribute('async', '');
        script1.setAttribute(
          'src',
          `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`
        );

        const script2 = document.createElement('script');
        script2.appendChild(
          document.createTextNode(`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}');
          `)
        );

        document.head.append(script1, script2);
      }
    }

    validateScripts();
  }, [email]);

  return (
    <ScriptsContext.Provider value={null}>{children}</ScriptsContext.Provider>
  );
};
