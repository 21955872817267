import React from 'react';

import * as S from './styles';

type AccordionContentProps = React.ComponentProps<typeof S.AccordionContent> & {
  children: React.ReactNode;
};

export const AccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, ...props }: any, forwardedRef) => (
  <S.AccordionContent {...props} ref={forwardedRef}>
    <S.AccordionContentText>{children}</S.AccordionContentText>
  </S.AccordionContent>
));
