import { toast } from 'react-toastify';

import axios, { AxiosRequestConfig } from 'axios';
import { History } from 'history';
import { forEach, get, includes } from 'lodash';
import Cookies from 'universal-cookie';

import { AuthCookiesKey } from '@utils/constants/AuthCookiesKey';
import { StatusCode } from '@utils/constants/StatusCode';

const injectSessionToken = async (config: AxiosRequestConfig) => {
  try {
    const cookies = new Cookies();
    const sessionId = cookies.get(AuthCookiesKey.AUTH_SESSION_ID);

    if (!config.headers) {
      config.headers = {};
    }

    if (sessionId) {
      config.headers['sessid'] = sessionId;
    }

    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

const handleError = (responseError: any, history: History) => {
  const { status } = responseError;

  switch (status) {
    case StatusCode.UNAUTHORIZED: {
      toast.warning('Sua sessão expirou. Aguarde um momento...', {
        toastId: 'sessionExpired'
      });

      /**
       * TODO
       * Quando tiver implementado o refresh token no backed,
       * fazer o refresh e redirecionar para a página de faturas
       */
      // refreshAuthentication();
      // history.push('/faturas');

      history.replace('/');
      break;
    }
    case StatusCode.REDIRECT: {
      if (!includes(history.location.pathname, 'auth')) {
        const redirectUrl = get(responseError, 'headers.redirecturl');
        const sessionId = get(responseError, 'headers.sessid');

        if (redirectUrl) {
          const cookies = new Cookies();
          const expires = new Date();
          expires.setHours(expires.getHours() + 8);

          cookies.set(AuthCookiesKey.AUTH_SESSION_ID, sessionId, {
            expires,
            secure: true
          });

          window.location.replace(redirectUrl);
        }
      }

      break;
    }
    case StatusCode.NOT_FOUND: {
      toast.error('Página não encontrada');
      break;
    }
    case StatusCode.UNPROCESSED_ENTITY:
    default: {
      if (responseError.data.messages) {
        forEach(responseError.data.messages, (message) => {
          toast.error(message);
        });
      } else if (responseError.data.message) {
        toast.error(responseError.data.message);
      } else {
        toast.error('Ocorreu um erro inesperado');
      }
      break;
    }
  }

  return Promise.reject(responseError);
};

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL_API}/v1`
});

export const setupInterceptors = (history: History) => {
  api.interceptors.request.use(injectSessionToken, (error) =>
    Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;
      return handleError(response, history);
    }
  );
};
