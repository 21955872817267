import styled, { css, DefaultTheme } from 'styled-components';

const variantModifiers = {
  link: (theme: DefaultTheme) => css`
    color: ${theme.colors.feedback.link};

    &:hover {
      color: ${theme.colors.feedback.info};
      background-color: ${theme.colors.feedback.infoLight};
      transition: background-color 200ms;
    }
  `,
  light: (theme: DefaultTheme) => css`
    color: ${theme.colors.text.grayscaleLight6};

    &:hover {
      color: #f8f8f8;
      background-color: ${theme.colors.text.grayscale};
      transition: background-color 200ms;
    }
  `,
  dark: (theme: DefaultTheme) => css`
    color: ${theme.colors.text.grayscaleLight2};

    &:hover {
      color: ${theme.colors.text.grayscaleLight1};
      background-color: ${theme.colors.text.grayscaleLight5};
      transition: background-color 200ms;
    }
  `
};

const sizeModifiers = {
  small: (theme: DefaultTheme) => css`
    min-width: 28px;
    min-height: 28px;

    svg {
      width: ${theme.font.sizes.medium};
      height: ${theme.font.sizes.medium};
    }
  `,
  medium: (theme: DefaultTheme) => css`
    min-width: 32px;
    min-height: 32px;

    svg {
      width: ${theme.font.sizes.h5};
      height: ${theme.font.sizes.h5};
    }
  `,
  large: (theme: DefaultTheme) => css`
    min-width: 36px;
    min-height: 36px;

    svg {
      width: ${theme.font.sizes.h4};
      height: ${theme.font.sizes.h4};
    }
  `
};

export const Button = styled.button<{
  variant: 'link' | 'light' | 'dark';
  size: 'small' | 'medium' | 'large';
}>`
  align-items: center;
  appearance: none;
  border-width: 0;
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  font-family: inherit;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 0;
  text-decoration: none;
  user-select: none;
  background: transparent;
  border-radius: ${({ theme }) => theme.border.radius.circle};

  ${({ theme, variant }) => variantModifiers[variant](theme)}
  ${({ theme, size }) => sizeModifiers[size](theme)}

  &::before {
    box-sizing: border-box;
  }

  &::after {
    box-sizing: border-box;
  }

  &:disabled {
    pointer-events: none;
  }

  &:nth-child(0n + 1) {
    margin-right: 4px;
  }
`;
