import styled, { css, DefaultTheme } from 'styled-components';

import { AvatarProps } from '.';

const wrapperModifiers = {
  xsmall: (theme: DefaultTheme) => css`
    width: 24px;
    height: 24px;
    font-size: ${theme.font.sizes.smaller};
  `,
  small: (theme: DefaultTheme) => css`
    width: 32px;
    height: 32px;
    font-size: ${theme.font.sizes.small};
  `,
  medium: (theme: DefaultTheme) => css`
    width: 40px;
    height: 40px;
    font-size: ${theme.font.sizes.medium};
  `,
  large: (theme: DefaultTheme) => css`
    width: 56px;
    height: 56px;
    font-size: ${theme.font.sizes.h5};
  `,
  xlarge: (theme: DefaultTheme) => css`
    width: 80px;
    height: 80px;
    font-size: ${theme.font.sizes.h4};
  `
};

export const Wrapper = styled.div<Pick<AvatarProps, 'size'>>`
  ${({ theme, size }) => css`
    border-radius: ${theme.border.radius.circle};
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: ${theme.colors.brand.primary};
    color: ${theme.colors.brand.white};

    font-weight: ${theme.font.bold};

    ${!!size && wrapperModifiers[size](theme)};

    img {
      ${!!size && wrapperModifiers[size](theme)};
      border-radius: ${theme.border.radius.circle};
    }
  `}
`;
