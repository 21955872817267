import { createContext, useState, useContext, ReactNode } from 'react';

type DrawerContextData = {
  notificationOpen: boolean;
  notificationCount: number;
  setNotificationOpen: () => void;
};

const DrawerContext = createContext({} as DrawerContextData);

type DrawerProviderProps = {
  children: ReactNode;
};

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [notificationDrawerIsOpen, setNotificationDrawerIsOpen] =
    useState(false);
  const setNotificationOpen = () => {
    setNotificationDrawerIsOpen(!notificationDrawerIsOpen);
  };
  return (
    <DrawerContext.Provider
      value={{
        notificationOpen: notificationDrawerIsOpen,
        notificationCount: 1,
        setNotificationOpen
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);
