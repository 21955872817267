export default {
  grid: {
    container: '1300px',
    gutter: '32px'
  },
  boxShadow: {
    shadow2: '0px 1px 3px rgba(96, 97, 112, 0.16)',
    shadow4: '0px 4px 8px rgba(96, 97, 112, 0.16)',
    shadow8: '0px 16px 24px rgba(96, 97, 112, 0.16)',
    shadow10: '0px 20px 32px rgba(96, 97, 112, 0.24)'
  },
  border: {
    sizes: {
      border1: '1px',
      border2: '2px'
    },
    radius: {
      square: '0px',
      softlyRounded: '4px',
      mediumRounded: '8px',
      circle: '50%'
    }
  },
  font: {
    family: "'Roboto', sans-serif",
    normal: 400,
    strong: 600,
    bold: 700,
    sizes: {
      tiny: '10px',
      smaller: '12px',
      small: '14px',
      medium: '16px',
      h6: '18px',
      h5: '20px',
      h4: '24px',
      h3: '28px',
      h2: '32px',
      h1: '40px'
    }
  },
  colors: {
    brand: {
      primary: '#FF4800',
      primaryLight1: '#FF6A30',
      primaryLight2: '#FF8D61',
      primaryLight3: '#FFDACC',
      secondary: '#000000',
      secondaryLight1: '#34363D',
      secondaryLight2: '#5E5E5E',
      tertiary: '#090051',
      tertiaryLight1: '#283785',
      tertiaryLight2: '#AAB3DF',
      white: '#FFFFFF'
    },
    text: {
      grayscale: '#353840',
      grayscaleLight1: '#656872',
      grayscaleLight2: '#A4A6AC',
      grayscaleLight3: '#CFCFD1',
      grayscaleLight4: '#E5E5E5',
      grayscaleLight5: '#EFEFEF',
      grayscaleLight6: '#F8F8F8'
    },
    feedback: {
      link: '#0075FF',
      info: '#4B65EC',
      infoDarken: '#1B2F9A',
      infoLight: '#ECEFFD',
      success: '#0BA248',
      successDarken: '#047833',
      successLight: '#E5F8EC',
      error: '#EB3B46',
      errorDarken: '#C70A15',
      errorLight: '#FFEBEC',
      warning: '#F7C544',
      warningDarken: '#ECAC08',
      warningLight: '#FEF8EA'
    }
  },
  spacings: {
    xxsmall: '4px',
    xsmall: '8px',
    small: '16px',
    medium: '24px',
    large: '32px',
    xlarge: '48px',
    xxlarge: '64px'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
} as const;
