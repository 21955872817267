import React, { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import { LinearLoader } from '@components/LinearLoader';

type CustomSwitchProps = {
  children: React.ReactNode;
};

export const CustomSwitch = ({ children }: CustomSwitchProps) => {
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    setPrevLoc(pathname);
    setProgress(true);
    if (pathname === prevLoc) {
      setPrevLoc('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  return (
    <>
      {progress && <LinearLoader />}
      <Switch>{children}</Switch>
    </>
  );
};
