import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

import LoadingContent from '@templates/LoadingContent';

const HomePage: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <Helmet>
        <title>Financeiro | MyClear</title>
      </Helmet>
      <LoadingContent />
    </>
  );
};

export default HomePage;
