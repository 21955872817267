import { ToastContainer } from 'react-toastify';

import { down } from 'styled-breakpoints';
import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ${({ theme }) => css`
    //scrollbar configuration
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: ${theme.colors.text.grayscaleLight4};
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.text.grayscaleLight2};
      border-radius: 16px;
    }

    // selection
    ::selection {
      background: ${theme.colors.brand.primaryLight1};
      color: ${theme.colors.brand.white};
    }

    body {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.text.grayscale};
      font-weight: ${theme.font.normal};
    }

    /* REACT DATEPICKER (CALENDAR) */
    .react-datepicker-wrapper {
      position: relative;
      display: inline-grid;
    }

    .react-datepicker-popper[data-placement^='bottom'] {
      margin-top: -19px;
      padding-top: 0;
      offset: none;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.text.grayscale};
      font-weight: ${theme.font.normal};
      border-radius: ${theme.border.radius.mediumRounded};
      border: none;
      background: ${theme.colors.text.grayscaleLight6};
      height: auto;
      min-width: calc(300px - 32px);
    }

    .react-datepicker__month-container {
      display: flex;
      flex-direction: column;
      float: none;
    }

    .react-datepicker__header {
      border: none;
      background: ${theme.colors.brand.primary};
      padding: ${theme.spacings.xsmall};
      font-size: ${theme.font.sizes.h5};
      color: ${theme.colors.brand.secondary};
      font-weight: ${theme.font.normal};
      line-height: ${theme.font.sizes.h4};
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      background: ${theme.colors.brand.primary};
      font-size: ${theme.font.sizes.h5};
      color: ${theme.colors.brand.white};
      font-weight: ${theme.font.normal};
      line-height: ${theme.font.sizes.h4};
      text-transform: capitalize;
    }

    .react-datepicker__navigation {
      margin-top: ${theme.spacings.xsmall};
      color: ${theme.colors.brand.white};
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      border-color: ${theme.colors.brand.white};
    }

    .react-datepicker__navigation:hover *::before {
      border-color: ${theme.colors.brand.tertiaryLight1};
    }

    .react-datepicker__month-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-transform: capitalize;
    }

    .react-datepicker__month {
      padding: ${theme.spacings.small} ${theme.spacings.xsmall};
      text-align: center;
      margin: 0;
      background-color: ${theme.colors.text.grayscaleLight6};
      border: 1px solid ${theme.colors.text.grayscaleLight5};
      border-bottom-left-radius: ${theme.border.radius.mediumRounded};
      border-bottom-right-radius: ${theme.border.radius.mediumRounded};
    }

    .react-datepicker__month .react-datepicker__month-text {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      margin: 10px 0;
    }

    .react-datepicker__month .react-datepicker__month-text,
    .react-datepicker__month .react-datepicker__quarter-text {
      width: 100%;
      height: 30px;
      margin: 10px 0;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: ${theme.colors.brand.tertiaryLight1} !important;
      color: ${theme.colors.brand.white} !important;
      border-radius: ${theme.border.radius.mediumRounded} !important;
      &:hover {
        border-radius: ${theme.border.radius.mediumRounded};
        background-color: ${theme.colors.brand.tertiary};
      }
    }

    .react-datepicker__month-text.react-datepicker__month--selected:hover,
    .react-datepicker__month-text.react-datepicker__month--in-range:hover {
      background-color: ${theme.colors.brand.tertiaryLight1};
      border-radius: ${theme.border.radius.mediumRounded};
      color: ${theme.colors.brand.white};
    }

    .react-datepicker__month--selected,
    .react-datepicker__month--in-selecting-range,
    .react-datepicker__month--in-range,
    .react-datepicker__quarter--selected,
    .react-datepicker__quarter--in-selecting-range,
    .react-datepicker__quarter--in-range {
      background-color: ${theme.colors.brand.tertiaryLight2};
      border-radius: ${theme.border.radius.mediumRounded};
      padding: 2px 0;
      color: ${theme.colors.text.grayscale};
    }

    .react-datepicker__day {
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.normal};
      color: ${theme.colors.text.grayscale};

      &:hover {
        border-radius: ${theme.border.radius.mediumRounded};
      }
    }

    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today,
    .react-datepicker__year-text--today {
      font-weight: normal;
    }

    .react-datepicker__day-names {
      background-color: ${theme.colors.text.grayscaleLight6};
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0 ${theme.spacings.small};
    }

    .react-datepicker__day--outside-month {
      color: ${theme.colors.text.grayscaleLight3};
    }

    .react-datepicker__day-name {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.text.grayscale};
      text-transform: uppercase;
    }

    .react-datepicker__week {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  `}
`;

export const StyledToastContainer = styled(ToastContainer)`
  ${({ theme }) => css`
    &&&.Toastify__toast-container {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacings.xsmall};
      padding: 0;
      min-width: 606px;
      min-height: 48px;
      border-radius: ${theme.border.radius.softlyRounded};

      ${down('md')} {
        min-width: auto;
      }
    }
    .Toastify__toast {
      padding: 0;
      margin: 0;
      height: 48px;
      min-height: 48px;
      background-color: ${theme.colors.text.grayscale};
      color: ${theme.colors.brand.white};
      border-radius: ${theme.border.radius.softlyRounded};
    }
    .Toastify__toast-body {
      margin: 0;
      font-size: ${theme.font.sizes.small};
      padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
    }
    .Toastify__close-button {
      height: 100%;
      padding: 0 ${theme.spacings.small} 0;

      svg {
        color: ${theme.colors.brand.white};
      }
    }
  `}
`;
