import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';

import { FloatingDownloadUpload } from '@components/FloatingDownloadUpload';
import { NotificationDrawer } from '@components/NotificationDrawer';
import { PageHeader } from '@components/PageHeader';
import { SideBar } from '@components/SideBar';
import { ScriptsProvider } from '@providers/ScriptsProvider';

import * as S from './styles';

export type BaseTemplateProps = {
  children: React.ReactNode;
  pageTitle: string;
};

const Base = ({ children, pageTitle }: BaseTemplateProps) => {
  const isDesktop = useBreakpoint(up('sm'));

  return (
    <ScriptsProvider>
      <S.Wrapper>
        {isDesktop && <SideBar />}
        <NotificationDrawer />
        <FloatingDownloadUpload />
        <S.Content>
          <PageHeader>{pageTitle}</PageHeader>
          <S.Children>{children}</S.Children>
        </S.Content>
      </S.Wrapper>
    </ScriptsProvider>
  );
};

export default Base;
