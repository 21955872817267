import styled, { css, keyframes } from 'styled-components';

import * as AccordionPrimitive from '@radix-ui/react-accordion';

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
    }
`;

export const AccordionContent = styled(AccordionPrimitive.Content)`
  ${({ theme }) => css`
    overflow: hidden;
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    line-height: 24px;
    color: ${theme.colors.text.grayscale};
    background-color: ${theme.colors.brand.white};

    &[data-state='open'] {
      animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
    &[data-state='closed'] {
      animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
  `}
`;

export const AccordionContentText = styled.div`
  padding: 12px ${({ theme }) => theme.spacings.small};
`;
