import styled, { css } from 'styled-components';

export type DividerProps = {
  gradientTheme: 'dark' | 'orange' | 'menu';
};

const dividerModifiers = {
  dark: () => css`
    background: linear-gradient(
      90deg,
      rgba(94, 94, 94, 0) 0%,
      #5e5e5e 48.96%,
      rgba(94, 94, 94, 0) 100%
    );
  `,

  orange: () => css`
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 48.96%,
      rgba(255, 255, 255, 0) 100%
    );
  `,

  menu: () => css`
    background: linear-gradient(
      90deg,
      rgba(239, 239, 239, 0) 0%,
      #e5e5e5 52.08%,
      rgba(239, 239, 239, 0) 100%
    );
    width: 100%;
  `
};

export const Divider = styled.div.attrs({
  'aria-label': 'Divider'
})<DividerProps>`
  ${({ theme, gradientTheme }) => css`
    width: ${theme.spacings.xxlarge};
    height: 1px;
    margin: ${theme.spacings.small} 0;

    ${!!gradientTheme && dividerModifiers[gradientTheme]}
  `}
`;
