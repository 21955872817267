import styled, { css } from 'styled-components';

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: ${theme.spacings.small} ${theme.spacings.medium};
    background-color: ${theme.colors.text.grayscaleLight6};
    color: ${theme.colors.brand.primary};
    height: 72px;

    h1 {
      font-size: ${theme.font.sizes.h5};
      font-weight: ${theme.font.bold};
      line-height: 28px;
      margin: 0;
    }

    svg {
      width: ${theme.font.sizes.h4};
      height: ${theme.font.sizes.h4};
    }
  `}
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    border-radius: ${theme.border.radius.circle};

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:hover {
      background-color: ${theme.colors.brand.primaryLight3};
      transition: background-color ${theme.transition.default};
    }
  `}
`;
