import { components, DropdownIndicatorProps } from 'react-select';

import { ChevronDown as ChevronDownIcon } from '@styled-icons/ionicons-outline';

import type { OptionType } from '../';
import * as S from '../styles';

export const DropdownIndicator = (
  props: DropdownIndicatorProps<OptionType, true>
) => {
  const {
    selectProps: { menuIsOpen }
  } = props;

  return (
    <components.DropdownIndicator {...props}>
      <S.MultiAutocompleteInputIndicator
        menuOpen={menuIsOpen}
        aria-label={menuIsOpen ? 'fechar menu' : 'abrir menu'}
      >
        <ChevronDownIcon />
      </S.MultiAutocompleteInputIndicator>
    </components.DropdownIndicator>
  );
};
