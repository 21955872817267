import styled, { css } from 'styled-components';

export const NotificationListItem = styled.li<{ read: boolean }>`
  ${({ theme, read }) => css`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    ${!read
      ? `
        &::before {
          content: '●';
          width: 30px;
          color: ${theme.colors.brand.primary};
        }
        `
      : `
        &::before {
          content: '';
          width: 30px;
          color: ${theme.colors.brand.primary};
        }
      `}
    h6 {
      color: ${theme.colors.text.grayscale};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.small};
      time {
        color: ${theme.colors.text.grayscaleLight2};
        font-weight: ${theme.font.normal};
        font-size: ${theme.font.sizes.tiny};
        &::before {
          content: '•';
          margin: 0 6px;
        }
      }
    }
    p {
      color: ${theme.colors.text.grayscale};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.small};
      margin: 0;
      a {
        color: ${theme.colors.feedback.link};
        font-weight: ${theme.font.normal};
        font-size: ${theme.font.sizes.small};
        text-decoration: underline;
      }
    }
  `}
`;

export const NotificationListDivider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    margin: ${theme.spacings.small} 0;
    background: linear-gradient(
      90deg,
      rgba(239, 239, 239, 0) 0%,
      #e5e5e5 52.08%,
      rgba(239, 239, 239, 0) 100%
    );
    transform: rotate(-180deg);
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  `}
`;
