import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { Button } from '@components/Button';
import { Logo } from '@components/Logo';
import {
  LogoFacebook,
  LogoTwitter,
  LogoYoutube,
  LogoLinkedin
} from '@styled-icons/ionicons-solid';
import { parseYearNumber } from '@utils/parseDateFormat';

import * as S from './styles';

const Page404 = () => {
  const history = useHistory();
  const currentYear = parseYearNumber(new Date());

  return (
    <S.Wrapper>
      <S.Header>
        <Logo variants="horizontal" size="full" />
      </S.Header>
      <S.Content>
        <S.Title>Página não encontrada</S.Title>
        <S.Description>
          A página que você tentou acessar não existe. Você pode entrar em
          contato com nosso suporte técnico para mais informações.
        </S.Description>
        <Button onClick={() => history.push('/faturas')}>Voltar</Button>
      </S.Content>
      <S.Footer>
        © {currentYear} ClearSale S.A. Todos os direitos reservados.
        <S.WrapperSocialMedia>
          {!isEmpty(process.env.REACT_APP_FACEBOOK_URL) && (
            <a href={process.env.REACT_APP_FACEBOOK_URL} target="__blank">
              <LogoFacebook size={24} />
            </a>
          )}
          {!isEmpty(process.env.REACT_APP_TWITTER_URL) && (
            <a href={process.env.REACT_APP_TWITTER_URL} target="__blank">
              <LogoTwitter size={24} />
            </a>
          )}
          {!isEmpty(process.env.REACT_APP_YOUTUBE_URL) && (
            <a href={process.env.REACT_APP_YOUTUBE_URL} target="__blank">
              <LogoYoutube size={24} />
            </a>
          )}
          {!isEmpty(process.env.REACT_APP_LINKEDIN_URL) && (
            <a href={process.env.REACT_APP_LINKEDIN_URL} target="__blank">
              <LogoLinkedin size={24} />
            </a>
          )}
        </S.WrapperSocialMedia>
      </S.Footer>
    </S.Wrapper>
  );
};

export default Page404;
