import React from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import * as S from './styles';

export type TooltipProps = React.ComponentProps<
  typeof TooltipPrimitive.Root
> & {
  children: React.ReactElement;
  content: React.ReactNode;
  side?: 'top' | 'right' | 'left' | 'bottom';
  align?: 'start' | 'center' | 'end';
  asChild?: boolean;
};

export const Tooltip = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  side = 'top',
  align = 'center',
  asChild = false,
  ...rest
}: TooltipProps) => {
  return (
    <TooltipPrimitive.Root
      delayDuration={200}
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
    >
      <S.Trigger asChild={asChild}>{children}</S.Trigger>

      <S.Content side={side} align={align} sideOffset={5} {...rest}>
        <S.Text>{content}</S.Text>
        <S.Box>
          <TooltipPrimitive.Arrow
            offset={5}
            width={14}
            height={9}
            style={{
              fill: 'currentcolor'
            }}
          />
        </S.Box>
      </S.Content>
    </TooltipPrimitive.Root>
  );
};
