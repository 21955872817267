import { useHistory } from 'react-router-dom';

import { isFunction } from 'lodash';

import { ArrowBack as ArrowBackIcon } from '@styled-icons/ionicons-outline';

import * as S from './styles';

type PageHeaderProps = {
  children: React.ReactNode;
  canBack?: boolean;
  onClick?: () => void;
};

export const PageHeader = ({
  children,
  canBack = false,
  onClick = undefined
}: PageHeaderProps) => {
  const { goBack } = useHistory();

  return (
    <S.Header>
      {canBack && (
        <S.BackButton
          role="button"
          title="Voltar"
          onClick={() => {
            if (isFunction(onClick)) {
              onClick();
            }

            goBack();
          }}
        >
          <ArrowBackIcon />
        </S.BackButton>
      )}
      <h1>{children}</h1>
    </S.Header>
  );
};
