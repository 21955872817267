import React, { forwardRef, useState } from 'react';

import { IconButton } from '@components/IconButton';
import {
  Search as SearchIcon,
  Close as CloseIcon,
  Eye as EyeIcon,
  EyeOff as EyeOffIcon
} from '@styled-icons/ionicons-outline';

import * as S from './styles';

type TextFieldProps = React.ComponentProps<'input'> & {
  name: string;
  label: React.ReactNode | string;
  isRequired?: boolean;
  helperText?: string;
  invalidText?: string;
  clearSearch?: () => void;
  fullWidth?: boolean;
  className?: string;
};

const TextFieldBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    name,
    label,
    className,
    clearSearch,
    type = 'text',
    isRequired = false,
    helperText = '',
    invalidText = '',
    autoFocus = false,
    fullWidth = false,
    ...rest
  },
  ref
) => {
  const [inputType, setInputType] = useState(type);

  const handleChangeType = () => {
    /* istanbul ignore next line */
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const handleClear = () => {
    /* istanbul ignore next line */
    if (type === 'search' && clearSearch) {
      clearSearch();
    }
  };

  const requiredText = isRequired === true ? ' *' : '';
  const isInvalid = !!invalidText;
  const hasAdornment = type === 'password' || type === 'search';

  return (
    <S.TextFieldBase fullWidth={fullWidth}>
      <S.TextFieldControl isInvalid={isInvalid} className={className}>
        <S.TextField
          type={type}
          id={name}
          name={name}
          autoFocus={autoFocus}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          hasAdornment={hasAdornment}
          {...rest}
          ref={ref}
          placeholder=" "
        />
        <S.TextFieldLabel htmlFor={name}>
          {label}
          {requiredText}
        </S.TextFieldLabel>

        {type === 'search' && (
          <S.TextFieldAdornment>
            {rest.value ? (
              <IconButton
                title="limpar pesquisa"
                icon={<CloseIcon />}
                withTooltip={false}
                onClick={handleClear}
              />
            ) : (
              <SearchIcon aria-label="pesquisar" />
            )}
          </S.TextFieldAdornment>
        )}

        {type === 'password' && (
          <S.TextFieldAdornment>
            <IconButton
              title={inputType === 'password' ? 'mostrar' : 'ocultar'}
              icon={inputType === 'password' ? <EyeOffIcon /> : <EyeIcon />}
              withTooltip={false}
              onClick={handleChangeType}
            />
          </S.TextFieldAdornment>
        )}

        {helperText && !isInvalid && (
          <S.TextFieldHelperText role="note">
            {helperText}
          </S.TextFieldHelperText>
        )}

        {isInvalid && (
          <S.TextFieldErrorMessage role="alert">
            {invalidText}
          </S.TextFieldErrorMessage>
        )}
      </S.TextFieldControl>
    </S.TextFieldBase>
  );
};

export const TextField = forwardRef(TextFieldBase);
