import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useAuth } from '@providers/AuthProvider';
import LoadingContent from '@templates/LoadingContent';

const OidcCallbackPage = () => {
  const { signInCallback } = useAuth();

  useEffect(() => {
    signInCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Financeiro | MyClear</title>
      </Helmet>
      <LoadingContent />
    </>
  );
};

export default OidcCallbackPage;
