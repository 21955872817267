import { parseInitialName } from '@utils/parseNames';

import * as S from './styles';

export type AvatarProps = {
  photo?: string;
  name: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
};

export const Avatar = ({ name, photo, size = 'medium' }: AvatarProps) => (
  <S.Wrapper size={size} aria-label={`Avatar ${name}`}>
    {photo ? (
      <img src={photo} alt={name} />
    ) : (
      <span>{parseInitialName(name)}</span>
    )}
  </S.Wrapper>
);
