import { toUpper } from 'lodash';

import { IconButton } from '@components/IconButton';
import { useDownloadUpload } from '@providers/DownloadUploadProvider';
import {
  FileExcel as FileExcelIcon,
  FilePdf as FilePdfIcon,
  FileCsv as FileCsvIcon,
  FileArchive as FileArchiveIcon
} from '@styled-icons/fa-solid';
import { handleViewer } from '@utils/handleFile';
import { parseFullDate } from '@utils/parseDateFormat';
import { removeExtensionName } from '@utils/parseNames';
import { onlyLetters } from '@utils/stringExtensions';

import * as S from './styles';

export type CardItemProps = {
  name: string;
  extension: string;
  lastModified: Date;
  entityId: string;
  entityName: string;
  filterDate: Date;
};

export const CardItem = ({
  lastModified,
  extension,
  name,
  entityId,
  entityName,
  filterDate
}: CardItemProps) => {
  const { onDownload } = useDownloadUpload();
  const cleanExtension = toUpper(onlyLetters(extension));

  return (
    <S.Wrapper>
      <S.WrapperIcon>
        {cleanExtension === 'PDF' && <FilePdfIcon aria-label="PDF" />}

        {cleanExtension === 'CSV' && <FileCsvIcon aria-label="CSV" />}

        {cleanExtension === 'XLSX' && <FileExcelIcon aria-label="XLSX" />}

        {cleanExtension === 'ZIP' && <FileArchiveIcon aria-label="ZIP" />}
      </S.WrapperIcon>

      <S.Content>
        <S.ContentTitle>{removeExtensionName(name)}</S.ContentTitle>
        <S.ContentDate>Criado {parseFullDate(lastModified)}</S.ContentDate>
      </S.Content>

      <S.WrapperExtraInfo>
        <S.Contract>{entityName}</S.Contract>
        <S.Extension>Arquivo em {cleanExtension}</S.Extension>
      </S.WrapperExtraInfo>

      <S.WrapperActions>
        {cleanExtension === 'PDF' && (
          <IconButton
            onClick={() => handleViewer(entityId, name, filterDate)}
            icon={<S.Eye />}
            variant="dark"
            title={`Visualizar ${cleanExtension}`}
          />
        )}

        <IconButton
          onClick={() => onDownload(entityId, name, filterDate)}
          icon={<S.Download />}
          variant="dark"
          title={`Download ${cleanExtension}`}
        />
      </S.WrapperActions>
    </S.Wrapper>
  );
};
