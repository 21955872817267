import styled, { css, DefaultTheme } from 'styled-components';

export const TextFieldBase = styled.div<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

export const TextFieldControl = styled.div<{ isInvalid: boolean }>`
  ${({ theme, isInvalid }) => css`
    position: relative;
    margin-bottom: ${theme.spacings.medium};
    display: inline-grid;
    grid-area: search;
    width: 100%;

    ${isInvalid &&
    css`
      & > input {
        box-shadow: 0 0 0 1px ${theme.colors.feedback.errorDarken};
      }
      & > label {
        color: ${theme.colors.feedback.errorDarken};
      }
    `}
  `}
`;

export const TextFieldCss = (
  theme: DefaultTheme,
  hasAdornment?: boolean
) => css`
  all: unset;
  outline: none;
  position: relative;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.font.sizes.medium};
  gap: 10px;
  text-overflow: ellipsis;
  box-shadow: 0 0 0 1px ${theme.colors.text.grayscaleLight1};
  /* min-width: calc(300px - 32px); */
  height: 48px;
  padding: 0 ${theme.spacings.small};
  border-radius: ${theme.border.radius.softlyRounded};

  &:focus {
    box-shadow: 0 0 0 1px ${theme.colors.brand.primary};

    ~ label {
      top: -8px;
      line-height: 18px;
      font-size: ${theme.font.sizes.smaller};
      color: ${theme.colors.brand.primary};
    }
  }

  &::disabled {
    box-shadow: 0 0 0 1px ${theme.colors.text.grayscaleLight3};
    color: ${theme.colors.text.grayscaleLight3};

    ~ label {
      color: ${theme.colors.text.grayscaleLight3};
    }
  }

  &:not(:placeholder-shown):not(:focus) ~ label {
    top: -8px;
    line-height: 18px;
    font-size: ${theme.font.sizes.smaller};
  }

  &:not(:focus) ~ label {
    ${hasAdornment === true &&
    css`
      padding-right: 29px;
    `}
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const TextField = styled.input<{ hasAdornment?: boolean }>`
  ${({ theme, hasAdornment }) => TextFieldCss(theme, hasAdornment)}
`;

export const TextFieldAdornment = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;

  & svg {
    color: ${({ theme }) => theme.colors.text.grayscaleLight1};
    width: 24px;
    height: 24px;
  }

  & button:nth-child(0n + 1) {
    margin-right: 0;
  }
`;

export const TextFieldLabel = styled.label`
  ${({ theme }) => css`
    position: absolute;
    left: 0;
    top: 12px;
    cursor: text;
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.medium};
    margin: 0 13px;
    padding: 0 3px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    transition: top ${theme.transition.fast}, font-size ${theme.transition.fast},
      color ${theme.transition.fast};
    user-select: none;
    pointer-events: none;
    backdrop-filter: blur(20px);
    color: ${theme.colors.text.grayscale};
    line-height: 24px;
  `}
`;

export const TextFieldHelperText = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.smaller};
    line-height: 8px;
    color: ${theme.colors.text.grayscaleLight2};
    margin-top: ${theme.spacings.xsmall};
  `}
`;

export const TextFieldErrorMessage = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.smaller};
    line-height: 8px;
    color: ${theme.colors.feedback.errorDarken};
    margin-top: ${theme.spacings.xsmall};
  `}
`;
