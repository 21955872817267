import styled, { css } from 'styled-components';

import { Divider } from '@components/Divider';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

export const Accordion = styled(AccordionPrimitive.Root)`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.softlyRounded};
    background-color: ${theme.colors.brand.white};
  `}
`;

export const AccordionItem = styled(AccordionPrimitive.Item)`
  ${({ theme }) => css`
    overflow: hidden;
    margin-top: 1px;

    &:first-child {
      margin-top: 0;
      border-top-left-radius: ${theme.border.radius.softlyRounded};
      border-top-right-radius: ${theme.border.radius.softlyRounded};
    }

    &:last-child {
      border-bottom-left-radius: ${theme.border.radius.softlyRounded};
      border-bottom-right-radius: ${theme.border.radius.softlyRounded};
    }

    &:focus-within {
      position: relative;
      z-index: 1;
    }
  `}
`;

export const AccordionDivider = styled(Divider)`
  width: 100%;
  margin: 0;
`;
