import React from 'react';
import { components, ValueContainerProps } from 'react-select';

import { filter, size } from 'lodash';

import type { OptionType } from '../';

export const ValueContainer = ({
  children,
  ...rest
}: ValueContainerProps<OptionType>) => {
  const { getValue } = rest;
  const values = getValue();
  const filteredValues = filter(values, ({ value }) => value !== '*');
  const valuesLength = size(filteredValues);
  let valueLabel = '';

  if (valuesLength > 0) {
    valueLabel = rest.selectProps.getOptionLabel(filteredValues[0]);
  }
  if (valuesLength > 1) {
    valueLabel = `${valuesLength} Itens selecionados`;
  }

  const childToRender = React.Children.toArray(children).filter(
    ({ type }: any) =>
      ['Input', 'DummyInput', 'Placeholder'].indexOf(type.name) >= 0
  );

  return (
    <components.ValueContainer {...rest}>
      {!rest.selectProps.inputValue && valueLabel}
      {childToRender}
    </components.ValueContainer>
  );
};
