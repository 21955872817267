import styled, { css } from 'styled-components';

export const Wrapper = styled.aside`
  ${({ theme }) => css`
    height: 100vh;
    width: ${theme.spacings.xxlarge};
    padding: ${theme.spacings.small};
    z-index: 100;

    background: ${theme.colors.brand.secondary};
    color: ${theme.colors.text.grayscaleLight6};

    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  `}
`;

export const WrapperLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const WrapperNavigation = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// export const MenuButton = styled.button`
//   ${({ theme }) => css`
//     color: ${theme.colors.text.grayscaleLight6};
//     background-color: transparent;
//     border: none;
//     cursor: pointer;
//     width: ${theme.spacings.medium};
//     height: ${theme.spacings.medium};

//     transition: color ${theme.transition.default};
//   `}
// `;

export const WrapperInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  margin-top: ${({ theme }) => theme.spacings.small};

  /* &:last-child {
    margin-bottom: -5px;
  } */
`;
