import { down, up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.text.grayscaleLight6};
`;

export const Content = styled.div`
  display: block;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  ${up('sm')} {
    margin-left: 64px;
  }
`;

export const Children = styled.main`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings.large};

    ${down('sm')} {
      padding: ${theme.spacings.small};
    }
  `}
`;
