import { ApplicationMenu } from '@components/ApplicationMenu';
import { Divider } from '@components/Divider';
import { UserMenu } from '@components/UserMenu';

// import { Menu } from '@styled-icons/ionicons-outline'
// import { NotificationMenu } from '@components/NotificationMenu';

import * as S from './styles';

export const SideBar = () => {
  return (
    <S.Wrapper>
      <S.WrapperLogo>
        <a href={process.env.REACT_APP_MYCLEAR_URL} aria-label="MyClear link">
          <img src="/assets/images/logo.svg" alt="Logo ClearSale" />
        </a>
      </S.WrapperLogo>

      <Divider gradientTheme="dark" />

      <S.WrapperNavigation>
        {/* <S.MenuButton>
          <Menu />
        </S.MenuButton> */}
        <div></div>

        <S.WrapperInfo>
          {/* <S.WrapperInfoItem>
            <NotificationMenu />
          </S.WrapperInfoItem> */}
          <S.WrapperInfoItem>
            <ApplicationMenu />
          </S.WrapperInfoItem>
        </S.WrapperInfo>
      </S.WrapperNavigation>

      <Divider gradientTheme="dark" />

      <S.WrapperProfile>
        <UserMenu />
      </S.WrapperProfile>
    </S.Wrapper>
  );
};
