import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { setupInterceptors } from './http';

const InjectInterceptors: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    setupInterceptors(history);
  }, [history]);

  return null;
};

export default InjectInterceptors;
