import React, { forwardRef } from 'react';
import ReactDatePicker, {
  registerLocale,
  ReactDatePickerProps
} from 'react-datepicker';
import ReactInputMask from 'react-input-mask';

import { getYear } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import toString from 'lodash/toString';

import { IconButton } from '@components/IconButton';
import {
  ChevronBack as ChevronBackIcon,
  ChevronForward as ChevronForwardIcon
} from '@styled-icons/ionicons-outline';

import * as S from './styles';

registerLocale('pt-BR', ptBR);

type MonthPickerProps = Pick<
  ReactDatePickerProps,
  'startDate' | 'minDate' | 'maxDate' | 'disabled' | 'readOnly' | 'selected'
>;

export type MonthCalendarProps = MonthPickerProps & {
  name: string;
  label: React.ReactNode | string;
  onChange: (date: Date) => void;
  value?: Date;
  isRequired?: boolean;
  helperText?: string;
  invalidText?: string;
  fullWidth?: boolean;
  className?: string;
};

type MonthPickerBaseProps = Pick<
  MonthCalendarProps,
  | 'value'
  | 'label'
  | 'isRequired'
  | 'helperText'
  | 'name'
  | 'invalidText'
  | 'className'
>;

const MonthPickerBase: React.ForwardRefRenderFunction<
  ReactInputMask,
  MonthPickerBaseProps
> = (
  {
    name,
    label,
    value,
    className,
    isRequired = false,
    helperText = '',
    invalidText = '',
    ...rest
  },
  ref
) => {
  const requiredText = isRequired === true ? ' *' : '';
  const isInvalid = !!invalidText;

  return (
    <S.MonthCalendarControl isInvalid={isInvalid} className={className}>
      <S.MonthCalendar
        mask="99/9999"
        name={name}
        id={name}
        value={toString(value)}
        ref={ref}
        {...rest}
        placeholder=" "
      />
      <S.MonthCalendarLabel htmlFor={name}>
        {label}
        {requiredText}
      </S.MonthCalendarLabel>

      {helperText && !isInvalid && (
        <S.MontCalendarHelperText role="note">
          {helperText}
        </S.MontCalendarHelperText>
      )}

      {isInvalid && (
        <S.MonthCalendarErrorMessage role="alert">
          {invalidText}
        </S.MonthCalendarErrorMessage>
      )}
    </S.MonthCalendarControl>
  );
};

const MonthPicker = forwardRef(MonthPickerBase);

export const MonthCalendar = ({
  name,
  label,
  value,
  isRequired,
  helperText,
  invalidText,
  startDate = new Date(),
  fullWidth = false,
  ...rest
}: MonthCalendarProps) => {
  return (
    <S.MonthCalendarBase fullWidth={fullWidth}>
      <ReactDatePicker
        name={name}
        id={name}
        selected={value}
        startDate={startDate}
        locale="pt-BR"
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        renderCustomHeader={({
          decreaseYear,
          increaseYear,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
          date
        }) => (
          <S.Header>
            <IconButton
              title="Anterior"
              withTooltip={false}
              icon={<ChevronBackIcon />}
              onClick={decreaseYear}
              disabled={prevYearButtonDisabled}
              variant="light"
            />
            <span>{getYear(date)}</span>
            <IconButton
              title="Próximo"
              withTooltip={false}
              icon={<ChevronForwardIcon />}
              onClick={increaseYear}
              disabled={nextYearButtonDisabled}
              variant="light"
            />
          </S.Header>
        )}
        customInput={
          <MonthPicker
            name={name}
            label={label}
            helperText={helperText}
            invalidText={invalidText}
            isRequired={isRequired}
            {...rest}
          />
        }
        {...rest}
      />
    </S.MonthCalendarBase>
  );
};
