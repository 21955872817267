import React from 'react';

import * as S from './styles';

type AccordionTriggerProps = React.ComponentProps<typeof S.AccordionTrigger> & {
  children: React.ReactNode;
};

export const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, ...props }, forwardedRef) => (
  <S.AccordionHeader>
    <S.AccordionTrigger {...props} ref={forwardedRef}>
      {children}
      <S.AccordionIcon aria-hidden />
    </S.AccordionTrigger>
  </S.AccordionHeader>
));
