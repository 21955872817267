import { map, sumBy } from 'lodash';

import { CircularLoader } from '@components/CircularLoader';
import {
  FloatingType,
  useDownloadUpload
} from '@providers/DownloadUploadProvider';
import {
  CheckmarkCircle as CheckmarkCircleIcon,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  CloseCircle as CloseCircleIcon,
  Close as CloseIcon
} from '@styled-icons/ionicons-outline';

import * as S from './styles';

export const FloatingDownloadUpload = () => {
  const {
    data,
    visibleFloating,
    expandedList,
    type,
    onCloseFloating,
    onChangeExpandedList,
    onCancelRequest,
    onCancelAllRequests,
    onDownload
  } = useDownloadUpload();

  const processing = sumBy(data, ({ status }) =>
    status === 'processing' ? 1 : 0
  );

  let headerTitle = '';

  if (processing) {
    const plural = processing !== 1 ? 'ns' : 'm';

    headerTitle =
      type === FloatingType.DOWNLOAD
        ? `Exportando ${processing} ite${plural}`
        : `Importando ${processing} ite${plural}`;
  } else {
    const success = sumBy(data, ({ status }) => (status === 'success' ? 1 : 0));

    const plural = success !== 1 ? ['ns', 's'] : ['m', ''];

    headerTitle =
      type === FloatingType.DOWNLOAD
        ? `${success} ite${plural[0]} exportado${plural[1]}`
        : `${success} ite${plural[0]} importado${plural[1]}`;
  }

  return (
    <S.FloatingBox show={visibleFloating} aria-hidden={!visibleFloating}>
      <S.Header expandedContent={expandedList}>
        <span>{headerTitle}</span>

        <S.HeaderActions>
          <S.HeaderIconButton
            title={expandedList ? 'Minimizar' : 'Maximizar'}
            onClick={onChangeExpandedList}
          >
            {expandedList ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </S.HeaderIconButton>
          {processing ? (
            <S.HeaderTextButton onClick={onCancelAllRequests}>
              Cancelar
            </S.HeaderTextButton>
          ) : (
            <S.HeaderIconButton title="Fechar" onClick={onCloseFloating}>
              <CloseIcon />
            </S.HeaderIconButton>
          )}
        </S.HeaderActions>
      </S.Header>
      <S.Content expanded={expandedList} aria-expanded={expandedList}>
        {map(data, (item) => {
          let StatusComponent = null;

          switch (item.status) {
            case 'processing':
              StatusComponent = (
                <>
                  <S.ItemProcessing>
                    <CircularLoader size="small" />
                  </S.ItemProcessing>
                  <S.ItemCancelButton
                    title="Cancelar"
                    onClick={() => onCancelRequest(item)}
                  >
                    <CloseCircleIcon />
                  </S.ItemCancelButton>
                </>
              );
              break;
            case 'error':
              StatusComponent = (
                <S.ItemTryAgainButton
                  onClick={() =>
                    onDownload(item.entityId, item.title, item.date, item.id)
                  }
                >
                  Tentar novamente
                </S.ItemTryAgainButton>
              );
              break;
            case 'success':
              StatusComponent = <CheckmarkCircleIcon />;
              break;
            case 'canceled':
            default:
              break;
          }

          return (
            <S.Item key={item.id} status={item.status}>
              <span>{item.title}</span>
              {StatusComponent}
            </S.Item>
          );
        })}
      </S.Content>
    </S.FloatingBox>
  );
};
