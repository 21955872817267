import get from 'lodash/get';
import styled, { css, DefaultTheme } from 'styled-components';

import type { AlertProps } from '.';

const severityModifiers = {
  filled: (
    severity: 'info' | 'warning' | 'error' | 'success',
    theme: DefaultTheme
  ) => css`
    background-color: ${get(theme.colors, `feedback.${severity}`)};
    color: ${theme.colors.brand.white};
  `,
  standard: (
    severity: 'info' | 'warning' | 'error' | 'success',
    theme: DefaultTheme
  ) => css`
    background-color: ${get(theme.colors, `feedback.${severity}Light`)};
    color: ${get(theme.colors, `feedback.${severity}Darken`)};
  `
};

const justifyModifiers = {
  start: () => css`
    justify-content: flex-start;
  `,
  center: () => css`
    justify-content: center;
  `,
  end: () => css`
    justify-content: flex-end;
  `
};

export const AlertBody = styled.div<Omit<AlertProps, 'children'>>`
  ${({ theme, severity, variant, justify, fullWidth }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${justifyModifiers[justify!]()}
    gap: ${theme.spacings.xsmall};
    min-height: ${theme.spacings.xlarge};
    padding: ${theme.spacings.xsmall} ${theme.spacings.small};
    border-radius: ${theme.border.radius.softlyRounded};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.normal};
    line-height: 21px;

    ${fullWidth === true &&
    css`
      width: 100%;
    `}

    ${severityModifiers[variant!](severity!, theme)};

    svg {
      width: 18px;
    }
  `}
`;
