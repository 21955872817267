import { forwardRef, AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

import { CircularLoader } from '@components/CircularLoader';

import * as S from './styles';

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  size?: 'small' | 'medium' | 'large';
  primary?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  as?: React.ElementType;
  floatingActionButton?: boolean;
  backgroundColor?: string;
} & ButtonTypes;

const ButtonBase: React.ForwardRefRenderFunction<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps
> = (
  {
    children,
    size = 'medium',
    primary = true,
    disabled = false,
    isLoading = false,
    fullWidth = false,
    floatingActionButton = false,
    backgroundColor,
    ...props
  },
  ref
) => (
  <S.Wrapper
    size={size}
    primary={primary}
    isLoading={isLoading}
    disabled={disabled || isLoading}
    fullWidth={fullWidth}
    floatingActionButton={floatingActionButton}
    style={{ backgroundColor }}
    ref={ref}
    {...props}
  >
    {isLoading && (
      <S.ButtonLoading>
        <CircularLoader />
      </S.ButtonLoading>
    )}
    {!!children && <span>{children}</span>}
  </S.Wrapper>
);

export const Button = forwardRef(ButtonBase);
