import { useState } from 'react';

import { CircularLoader } from '@components/CircularLoader';
import { Tooltip } from '@components/Tooltip';

import * as S from './styles';

export type IconButtonProps = React.ComponentProps<typeof S.Button> & {
  icon: React.ReactElement;
  title: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'link' | 'light' | 'dark';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  withTooltip?: boolean;
};

export const IconButton = ({
  icon,
  onClick,
  title,
  disabled = false,
  type = 'button',
  variant = 'dark',
  size = 'medium',
  withTooltip = true,
  ...rest
}: IconButtonProps) => {
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    try {
      setLoading(true);

      const response = await onClick();
      setLoading(false);

      return response;
    } catch (e) {
      setLoading(false);
      return null;
    }
  }

  const button = (
    <S.Button
      onClick={handleClick}
      disabled={disabled || loading}
      variant={variant}
      size={size}
      type={type}
      title={title}
      {...rest}
    >
      {loading ? <CircularLoader /> : icon}
    </S.Button>
  );

  if (withTooltip) {
    return (
      <Tooltip asChild content={title} side="bottom">
        {button}
      </Tooltip>
    );
  }

  return button;
};
