import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth } from '@providers/AuthProvider';
import LoadingContent from '@templates/LoadingContent';

const SignOutPage: React.FC<RouteComponentProps> = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Financeiro | MyClear</title>
      </Helmet>
      <LoadingContent />
    </>
  );
};

export default SignOutPage;
