import { up, down, between } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export const InvoicesSection = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.brand.white};
    padding: ${theme.spacings.large};
    border-radius: 16px;

    ${down('sm')} {
      padding: ${theme.spacings.small};
    }
  `}
`;

export const WrapperList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacings.medium};

  @media (min-width: 2400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  ${up('xxl')} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${between('sm', 'md')} {
    grid-template-columns: 1fr 1fr;
  }

  ${down('sm')} {
    grid-template-columns: 1fr;
    grid-gap: ${({ theme }) => theme.spacings.small};
  }
`;

export const WrapperAlert = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

export const WrapperEmptyList = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.small};
    margin-top: ${theme.spacings.large};
  `}
`;

export const WrapperContent = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xlarge};
  `}
`;

export const WrapperFilter = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.medium};
  `}
`;

export const FilterAlert = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;

    & p {
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.font.sizes.h4};
      color: ${theme.colors.text.grayscaleLight1};
      margin: 0;

      b {
        color: ${theme.colors.text.grayscale};
      }
    }
  `}
`;

export const FilterInputs = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: ${theme.spacings.medium};
    width: 100%;

    ${down('xl')} {
      grid-template-columns: 1fr;
    }
  `}
`;

export const Empty = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${theme.font.sizes.h5};
    font-weight: ${theme.font.bold};
    line-height: ${theme.font.sizes.h5};
    color: ${theme.colors.brand.primary};
  `}
`;
