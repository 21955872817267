import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { IconButton } from '@components/IconButton';
import { EllipsisVertical } from '@styled-icons/ionicons-outline';

import * as S from './styles';

type Notification = {
  id: number;
  title: string;
  description: string;
  date: string;
  read: boolean;
};

type NotificationListItemProps = {
  notification: Notification;
};

export function NotificationListItem({
  notification
}: NotificationListItemProps) {
  const { title, description, date, read } = notification;

  return (
    <>
      <S.NotificationListItem read={read}>
        <div>
          <h6>
            {title}
            <time>
              {formatDistance(new Date(date), new Date(), {
                addSuffix: true,
                locale: ptBR
              })}
            </time>
          </h6>
          <p>
            {description}&nbsp;
            <a href="/action">Action</a>
          </p>
        </div>
        <div>
          <IconButton
            icon={<EllipsisVertical />}
            title="Opções"
            withTooltip={false}
          />
        </div>
      </S.NotificationListItem>
      <S.NotificationListDivider />
    </>
  );
}
