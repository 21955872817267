import React from 'react';
import { BrowserRouter, Route, RouteProps } from 'react-router-dom';

import map from 'lodash/map';
import { ThemeProvider } from 'styled-components';

import { CustomSwitch } from '@components/CustomSwitch';
import NotFoundPage from '@pages/NotFoundPage';
import { AuthProvider, ProtectedRoute } from '@providers/AuthProvider';
import { DownloadUploadProvider } from '@providers/DownloadUploadProvider';
import { DrawerProvider } from '@providers/DrawerProvider';
import { FiltersProvider } from '@providers/FiltersProvider';
import { configRoute, CustomRoute } from '@routes/config';
import InjectInterceptors from '@services/injectInterceptors';
import { GlobalStyles, StyledToastContainer } from '@styles/global';
import theme from '@styles/theme';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <DrawerProvider>
            <DownloadUploadProvider>
              <FiltersProvider>
                <GlobalStyles />
                <InjectInterceptors />
                <CustomSwitch>
                  {map(configRoute, (route: CustomRoute) => (
                    <Route
                      key={route.pageName}
                      path={route.path}
                      exact
                      component={(props: RouteProps) => {
                        const Component = route.component as React.ElementType;

                        if (route.private === true) {
                          return (
                            <ProtectedRoute>
                              <Component {...props} />
                            </ProtectedRoute>
                          );
                        }

                        return <Component {...props} />;
                      }}
                    />
                  ))}
                  <Route exact component={NotFoundPage} />
                </CustomSwitch>
              </FiltersProvider>
            </DownloadUploadProvider>
          </DrawerProvider>
        </AuthProvider>
        <StyledToastContainer position="bottom-center" hideProgressBar />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
