import styled, { css } from 'styled-components';

import { Content } from '@templates/Base/styles';

const openModifiers = {
  true: () => css`
    left: 80px;
    ${Content} & {
      position: fixed;
      left: calc(64px + 16px + 362px);
      transition: left 300ms ease-in-out;
    }
  `,
  false: () => css`
    left: -362px;
  `
};

export const NotificationPanel = styled.nav<{ open: boolean }>`
  ${({ theme, open }) => css`
    position: fixed;
    top: 0;
    width: 362px;
    height: calc(100% - 32px);
    background-color: ${theme.colors.brand.white};
    padding: ${theme.spacings.medium} ${theme.spacings.small}
      ${theme.spacings.medium} ${theme.spacings.medium};
    margin: ${theme.spacings.small} 0;
    z-index: 50;
    cursor: default;
    border-radius: 24px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition: left 300ms ease-in-out;
    ${() => openModifiers[open ? 'true' : 'false']}
  `}
`;

export const NotificationHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      font-size: ${theme.font.sizes.h6};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.brand.secondary};
    }
  `}
`;

export const NotificationList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin-top: ${({ theme }) => theme.spacings.medium};
`;
