import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding: ${theme.spacings.large} ${theme.spacings.medium};
    background-color: ${theme.colors.brand.white};

    background-image: url('/assets/images/background404.svg');
    background-repeat: no-repeat;
    background-position: right top;

    ${down('sm')} {
      background-image: none;
      padding: ${theme.spacings.medium} ${theme.spacings.small};
    }
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium} ${theme.spacings.small};

    ${down('sm')} {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: ${theme.spacings.small} 0;
    }
  `}
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin-bottom: 100px;

  ${down('sm')} {
    margin-bottom: 0;
  }
`;

export const Title = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.h3};
    font-weight: ${theme.font.bold};
    line-height: ${theme.font.sizes.h1};
    color: #000000;
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.font.sizes.h4};
    color: #000000;

    width: 340px;
    padding-top: 16px;
    padding-bottom: 32px;
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    color: ${theme.colors.text.grayscaleLight1};
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.font.sizes.h4};

    ${down('sm')} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `}
`;

export const WrapperSocialMedia = styled.div`
  ${down('sm')} {
    margin-top: 10px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.grayscaleLight1};
  }

  svg {
    margin-right: ${({ theme }) => theme.spacings.xsmall};
  }
`;
