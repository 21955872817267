import { Popover } from 'react-bootstrap';

import styled, { css } from 'styled-components';

export const WrapperPopover = styled(Popover)`
  ${({ theme }) => css`
    border: none;
    box-shadow: ${theme.boxShadow.shadow10};
    border-radius: ${theme.border.radius.mediumRounded};

    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.normal};
    line-height: ${theme.font.sizes.h4};

    color: ${theme.colors.text.grayscale};

    .popover-arrow {
      position: absolute;
      top: 0 !important;
      transform: translate(0px, 255px) !important;
    }
  `}
`;

export const PopoverBody = styled(Popover.Body)`
  ${({ theme }) => css`
    /* height: fit-content; */
    width: 273px;
    background-color: ${theme.colors.brand.white};
    border-radius: ${theme.border.radius.mediumRounded};
    border: none;
    padding: ${theme.spacings.medium};
  `}
`;

export const PopoverUser = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    img {
      border-radius: ${theme.border.radius.circle};
    }
  `}
`;

export const PopoverWrapperInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: ${theme.spacings.xsmall};

    span {
      font-size: ${theme.font.sizes.smaller};
      color: ${theme.colors.text.grayscaleLight2};
      font-weight: ${theme.font.normal};
      line-height: ${theme.font.sizes.h6};
    }
  `}
`;

export const PopoverUserName = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`;

export const PopoverButtonItens = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    text-decoration: none;
    color: ${theme.colors.text.grayscale};

    width: 100%;
    border: none;
    background: transparent;
    margin-bottom: ${theme.spacings.xsmall};

    &:hover {
      color: ${theme.colors.brand.primary};
      cursor: pointer;

      svg {
        color: ${theme.colors.brand.primary};
      }
    }

    span {
      margin-left: 8px;
    }

    svg {
      width: 16px;
      height: 16px;
      color: ${theme.colors.text.grayscale};
    }
  `}
`;

export const PopoverGroupItens = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 100%;
  border: none;
  background: transparent;
`;

export const Wrapper = styled.div`
  cursor: pointer;
`;
