import { down, between } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

import {
  Download as IconDownload,
  Eye as IconEye
} from '@styled-icons/ionicons-outline';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 72px;

    padding: ${theme.spacings.small} ${theme.spacings.medium};

    display: grid;
    grid-template-columns: 0.5fr 2fr 1.5fr 1fr;
    align-content: center;
    align-items: center;
    grid-gap: 8px;

    background: ${theme.colors.brand.white};
    border-radius: ${theme.border.radius.mediumRounded};
    margin-bottom: ${theme.spacings.xsmall};
    box-shadow: ${theme.boxShadow.shadow2};

    ${between('sm', 'md')} {
      padding: ${theme.spacings.xsmall};
      margin-bottom: ${theme.spacings.xxsmall};
    }

    ${down('sm')} {
      width: 100%;
      max-width: 100%;

      padding: ${theme.spacings.xsmall};
      margin-bottom: ${theme.spacings.xxsmall};
    }
  `}
`;

export const WrapperIcon = styled.div`
  ${({ theme }) => css`
    svg {
      width: 50px;
      height: 50px;
    }

    color: ${theme.colors.brand.primaryLight2};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.text.grayscale};

    ${down('md')} {
      font-size: ${theme.font.sizes.smaller};
    }
  `}
`;

export const ContentDate = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.smaller};
    line-height: ${theme.font.sizes.h4};
    color: ${theme.colors.text.grayscaleLight2};
  `}
`;

export const WrapperExtraInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Extension = styled.span`
  ${({ theme }) => css`
    display: flex;
    font-size: ${theme.font.sizes.tiny};
    line-height: ${theme.font.sizes.small};
    color: ${theme.colors.text.grayscaleLight2};
  `}
`;

export const Contract = styled.span`
  ${({ theme }) => css`
    display: flex;
    font-size: ${theme.font.sizes.smaller};
    line-height: ${theme.font.sizes.h5};
    color: ${theme.colors.text.grayscaleLight2};
  `}
`;

export const WrapperActions = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  align-content: center;
`;

export const Download = styled(IconDownload)`
  border: none;
  position: relative;
`;

export const Eye = styled(IconEye)`
  border: none;
`;
