import { down } from 'styled-breakpoints';
import styled, { css, DefaultTheme } from 'styled-components';

import { ItemDownloadUploadProps } from '@providers/DownloadUploadProvider';

export const FloatingBox = styled.div<{ show: boolean }>`
  display: block;
  position: fixed;
  right: 16px;
  bottom: ${({ show }) => (show ? '16px' : '-100px')};
  width: 360px;
  box-shadow: ${({ theme }) => theme.boxShadow.shadow4};
  z-index: 100;

  transition: bottom ${({ theme }) => theme.transition.fast};

  ${down('xl')} {
    right: 72px;
    width: fit-content;
    min-width: 280px;
  }
`;

/**
 * Header components of floating box
 */

export const Header = styled.header<{ expandedContent: boolean }>`
  ${({ theme, expandedContent }) => css`
    background-color: ${theme.colors.text.grayscale};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px ${theme.spacings.small};
    gap: ${theme.spacings.small};
    border-radius: ${expandedContent
      ? `${theme.border.radius.softlyRounded} ${theme.border.radius.softlyRounded} 0 0`
      : theme.border.radius.softlyRounded};
    width: 100%;
    height: 48px;

    span {
      color: ${theme.colors.brand.white};
      font-size: ${theme.font.sizes.small};
      line-height: 24px;
    }
  `}
`;

export const HeaderActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.small};
  `}
`;

export const HeaderIconButton = styled.button`
  ${({ theme }) => css`
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: ${theme.colors.brand.white};
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    border-radius: ${theme.border.radius.circle};

    svg {
      width: ${theme.font.sizes.h5};
      height: ${theme.font.sizes.h5};
    }

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:hover {
      background-color: ${theme.colors.text.grayscaleLight1};
      transition: background-color 200ms;
    }
  `}
`;

export const HeaderTextButton = styled.button`
  ${({ theme }) => css`
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: ${theme.colors.feedback.warningDarken};
    font: inherit;
    font-size: ${theme.font.sizes.small};
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  `}
`;

/**
 * Content of list download or upload
 */

export const Content = styled.div<{ expanded: boolean }>`
  ${({ theme, expanded }) => css`
    background-color: ${theme.colors.brand.white};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.small};
    ${() => expandedModifiers[expanded ? 'true' : 'false'](theme)}
    border-radius: 0 0 ${theme.border.radius.softlyRounded}
      ${theme.border.radius.softlyRounded};
  `}
`;

const expandedModifiers = {
  true: (theme: DefaultTheme) => css`
    max-height: 160px;
    transition: padding ${theme.transition.default},
      max-height ${theme.transition.default};
    padding: ${theme.spacings.small} 0;
    overflow-y: auto;
    overflow-x: hidden;
  `,
  false: (theme: DefaultTheme) => css`
    max-height: 0;
    transition: padding ${theme.transition.fast},
      max-height ${theme.transition.fast};
    padding: 0;
    overflow: hidden;
  `
};

/**
 * Items of download or upload
 */

export const Item = styled.div<Pick<ItemDownloadUploadProps, 'status'>>`
  ${({ theme, status }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
    /* height: ${theme.spacings.medium}; */

    &:hover {
      background-color: ${theme.colors.text.grayscaleLight5};

      ${status === 'processing' &&
      css`
        ${ItemCancelButton} {
          display: block;
          opacity: 1;
          transition: opacity ${theme.transition.default};
        }

        ${ItemProcessing} {
          display: none;
          opacity: 0;
          transition: opacity ${theme.transition.default};
        }
      `}
    }

    span {
      font-size: ${theme.font.sizes.small};
      line-height: 24px;
      max-width: ${status === 'error' ? '180px' : '280px'};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    svg {
      width: ${theme.font.sizes.medium};
      height: ${theme.font.sizes.medium};
    }

    ${() => itemModifiers[status](theme)}
  `}
`;

export const ItemCancelButton = styled.button`
  ${({ theme }) => css`
    display: none;
    opacity: 0;

    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    border-radius: ${theme.border.radius.circle};

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:hover {
      background-color: ${theme.colors.text.grayscaleLight4};
      transition: background-color 200ms;
    }
  `}
`;

export const ItemTryAgainButton = styled.button`
  ${({ theme }) => css`
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.feedback.link};
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  `}
`;

export const ItemProcessing = styled.span`
  display: block;
  opacity: 1;
`;

const itemModifiers = {
  canceled: (theme: DefaultTheme) => css`
    color: ${theme.colors.text.grayscaleLight2};
    text-decoration: line-through;
  `,
  processing: (theme: DefaultTheme) => css`
    color: ${theme.colors.text.grayscale};
  `,
  error: (theme: DefaultTheme) => css`
    color: ${theme.colors.feedback.errorDarken};
  `,
  success: (theme: DefaultTheme) => css`
    color: ${theme.colors.text.grayscale};

    svg {
      color: ${theme.colors.feedback.success};
    }
  `
};
