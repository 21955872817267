import { createContext, useState, useContext, ReactNode } from 'react';

import { findIndex, isEmpty, map, some, uniqueId } from 'lodash';

import { useQueue } from '@hooks/useQueue';
import { handleDownload } from '@utils/handleFile';

export enum FloatingType {
  DOWNLOAD,
  UPLOAD
}

export type ItemDownloadUploadProps = {
  id: string;
  entityId: string;
  title: string;
  date: Date;
  status: 'canceled' | 'processing' | 'success' | 'error';
  controller?: AbortController;
};

type DownloadUploadContextData = {
  type?: FloatingType;
  data: ItemDownloadUploadProps[];
  visibleFloating: boolean;
  expandedList: boolean;
  onDownload: (
    entityId: string,
    filename: string,
    date: Date,
    tryAgainId?: string
  ) => void;
  onCloseFloating: () => void;
  onCancelRequest: (item: ItemDownloadUploadProps) => void;
  onChangeExpandedList: () => void;
  onCancelAllRequests: () => void;
};

const DownloadUploadContext = createContext({} as DownloadUploadContextData);

type DownloadUploadProviderProps = {
  children: ReactNode;
};

export const DownloadUploadProvider = ({
  children
}: DownloadUploadProviderProps) => {
  const [data, setData] = useState<ItemDownloadUploadProps[]>([]);
  const [type, setType] = useState<FloatingType | undefined>(undefined);
  const [expandedList, setExpandedList] = useState(true);

  const { addJob, empty } = useQueue();

  async function sendToQueue(
    entityId: string,
    filename: string,
    date: Date,
    tryAgainId?: string
  ) {
    const controller = new AbortController();
    let jobId = uniqueId();

    if (!isEmpty(tryAgainId)) {
      jobId = String(tryAgainId);

      setData((prevState) => {
        const index = findIndex(prevState, { id: tryAgainId });
        const newData = prevState;
        newData[index].status = 'processing';
        newData[index].controller = controller;
        return [...newData];
      });
    } else {
      setData((prevState) => [
        ...prevState,
        { entityId, id: jobId, title: filename, date, status: 'processing' }
      ]);
    }

    addJob({
      task: () => {
        handleDownload(entityId, filename, date, controller.signal)
          .then(() => {
            setData((prevState) => {
              const index = findIndex(prevState, { id: jobId });
              const newData = prevState;
              newData[index].status = 'success';
              newData[index].controller = undefined;
              return [...newData];
            });
          })
          .catch(() => {
            setData((prevState) => {
              const index = findIndex(prevState, { id: jobId });
              const newData = prevState;
              newData[index].status = 'error';
              newData[index].controller = undefined;
              return [...newData];
            });
          });
      }
    });
  }

  async function onDownload(
    entityId: string,
    filename: string,
    date: Date,
    tryAgainId?: string
  ) {
    setType(FloatingType.DOWNLOAD);
    await sendToQueue(entityId, filename, date, tryAgainId);
  }

  function onCloseFloating() {
    empty();
    setData([]);
    setType(undefined);
    setExpandedList(true);
  }

  function onChangeExpandedList() {
    setExpandedList(!expandedList);
  }

  function onCancelRequest(item: ItemDownloadUploadProps) {
    item.controller?.abort();

    const newItem: ItemDownloadUploadProps = {
      ...item,
      controller: undefined,
      status: 'canceled'
    };

    setData((prevState) => {
      const index = findIndex(prevState, { id: item.id });
      const newData = prevState;
      newData[index] = newItem;

      return [...newData];
    });
  }

  function onCancelAllRequests() {
    empty();

    setData((prevState) => {
      return map(prevState, (item) => {
        if (item.status === 'processing') {
          item.controller?.abort();

          item.controller = undefined;
          item.status = 'canceled';

          return item;
        }

        return item;
      });
    });
  }

  const visibleFloating = some(data);

  return (
    <DownloadUploadContext.Provider
      value={{
        type,
        data,
        visibleFloating,
        expandedList,
        onDownload,
        onCloseFloating,
        onCancelRequest,
        onChangeExpandedList,
        onCancelAllRequests
      }}
    >
      {children}
    </DownloadUploadContext.Provider>
  );
};

export const useDownloadUpload = () => useContext(DownloadUploadContext);
