import { Popover } from 'react-bootstrap';

import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    flex-shrink: 0;
    font-family: inherit;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    border-radius: ${theme.border.radius.circle};
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${theme.colors.text.grayscaleLight6};

    svg {
      width: ${theme.spacings.medium};
      height: ${theme.spacings.medium};
    }

    transition: color ${theme.transition.default},
      background-color ${theme.transition.default};

    &:hover {
      color: #f8f8f8;
      background-color: ${theme.colors.text.grayscale};
    }
  `}
`;

export const WrapperPopover = styled(Popover)`
  ${({ theme }) => css`
    border: none;
    box-shadow: ${theme.boxShadow.shadow10};
    border-radius: ${theme.border.radius.mediumRounded};

    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.smaller};
    font-weight: ${theme.font.bold};
    line-height: ${theme.font.sizes.h6};

    .popover-arrow {
      position: absolute;
      top: 0 !important;
      transform: translate(0px, 120px) !important;
    }
  `}
`;

export const PopoverBody = styled(Popover.Body)`
  ${({ theme }) => css`
    max-height: 1500px;
    width: 124px;

    background-color: ${theme.colors.brand.white};
    color: ${theme.colors.text.grayscaleLight2};
    border: none;
    border-radius: ${theme.border.radius.mediumRounded};

    padding: ${theme.spacings.medium} ${theme.spacings.large};

    overflow-y: auto;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  `}
`;

export const WrapperProductLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: ${theme.colors.text.grayscale};

    background: transparent;
    border: none;

    cursor: pointer;
    text-decoration: none;

    span {
      color: ${theme.colors.text.grayscale};
      font-weight: ${theme.font.normal};
    }
  `}
`;

export const ProductIcon = styled.div`
  ${({ theme }) => css`
    border: ${theme.border.sizes.border1} solid ${theme.colors.brand.primary};
    border-radius: ${theme.border.radius.circle};

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;

    margin: 10px;

    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
    line-height: ${theme.font.sizes.h4};
    color: ${theme.colors.brand.primary};
  `}
`;
