import { ControlProps, CSSObjectWithLabel } from 'react-select';

import styled, { css } from 'styled-components';

import * as TextFieldPrimitive from '@components/TextField/styles';
import themeBase from '@styles/theme';

export const MultiAutocompleteInputBase = styled.div`
  width: auto;
`;

export const MultiAutocompleteInputOption = styled.div`
  display: flex;
  align-items: center;
`;

export const MultiAutocompleteInputOptionCheckbox = styled.input`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;

    width: 16px;
    height: 16px;
    border: 2px solid ${theme.colors.text.grayscaleLight3};
    border-radius: 2px;
    transition: background border ${theme.transition.fast};
    position: relative;
    outline: none;

    margin-right: ${theme.spacings.xsmall};

    &:before {
      content: '';
      width: 6px;
      height: 9px;
      border: 2px solid ${theme.colors.brand.white};
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      opacity: 0;
      transition: ${theme.transition.fast};
    }
    &:focus {
      box-shadow: 0 0 5px ${theme.colors.brand.tertiaryLight1};
    }
    &:hover {
      border-color: ${theme.colors.brand.tertiaryLight2};
      box-shadow: 0 0 5px ${theme.colors.brand.tertiaryLight2};
      transition: ${theme.transition.fast};
    }
    &:checked {
      border-color: ${theme.colors.brand.tertiaryLight1};
      background: ${theme.colors.brand.tertiaryLight1};
      &:before {
        opacity: 1;
      }
    }
  `}
`;

export const MultiAutocompleteInputOptionLabel = styled.label<{
  isSelectAll: boolean;
}>`
  ${({ theme, isSelectAll }) => css`
    cursor: pointer;
    color: ${theme.colors.text.grayscale};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.font.sizes.h4};
    font-weight: ${isSelectAll ? theme.font.bold : theme.font.normal};
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
  `}
`;

export const MultiAutocompleteInputIndicator = styled.span<{
  menuOpen: boolean;
}>`
  ${({ theme, menuOpen }) => css`
    width: 20px;
    color: ${theme.colors.text.grayscaleLight1};
    transition: transform ${theme.transition.fast};

    transform: ${menuOpen ? 'rotate(180deg)' : 'rotate(0)'};
  `}
`;

export const MultiAutocompleteInputLabel = styled.label<
  Pick<ControlProps, 'isFocused' | 'hasValue' | 'isDisabled'> & {
    isInvalid: boolean;
  }
>`
  ${({ theme, isFocused, hasValue, isInvalid, isDisabled }) => css`
    position: absolute;
    left: 0;
    top: 12px;
    cursor: text;
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.medium};
    margin: 0 13px;
    padding: 0 3px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    transition: top ${theme.transition.fast}, font-size ${theme.transition.fast},
      color ${theme.transition.fast};
    user-select: none;
    pointer-events: none;
    backdrop-filter: blur(99px);
    color: ${theme.colors.text.grayscale};

    ${hasValue &&
    css`
      top: -8px;
      font-size: ${theme.font.sizes.smaller};
    `}

    ${isInvalid &&
    css`
      color: ${theme.colors.feedback.errorDarken};
    `}

    ${isFocused &&
    css`
      top: -8px;
      font-size: ${theme.font.sizes.smaller};
      color: ${theme.colors.brand.primary};
    `}

    ${isDisabled &&
    css`
      color: ${theme.colors.text.grayscaleLight3};
    `}
  `}
`;

export const MultiAutocompleteInputHelperText = styled(
  TextFieldPrimitive.TextFieldHelperText
)`
  position: absolute;
`;

export const MultiAutocompleteInputErrorMessage = styled(
  TextFieldPrimitive.TextFieldErrorMessage
)`
  position: absolute;
`;

export const MultiAutocompleteInputChipContainer = styled.div<{
  hasAssistiveText: boolean;
}>`
  ${({ hasAssistiveText }) => css`
    display: block;

    div {
      margin-right: ${({ theme }) => theme.spacings.xsmall};
      margin-bottom: ${({ theme }) => theme.spacings.xsmall};
    }

    ${hasAssistiveText
      ? css`
          margin-top: 24px;
        `
      : css`
          margin-top: 16px;
        `}
  `}
`;

export const customStylesReactSelect = {
  // multiValue: (base: CSSObjectWithLabel) => ({
  //   ...base,
  //   border: `none`,
  //   background: 'transparent',
  //   height: '32px',
  //   alignItems: 'center'
  // }),
  indicatorsContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    cursor: 'pointer',
    color: themeBase.colors.text.grayscaleLight1,
    div: {
      color: themeBase.colors.text.grayscaleLight1
    }
  }),
  container: (base: CSSObjectWithLabel) => ({
    ...base,
    minHeight: '48px'
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    top: 'auto'
  }),
  control: (
    base: CSSObjectWithLabel,
    state: ControlProps<{ label: string; value: string }, true>
  ) => {
    const focusedStyle = state.isFocused
      ? {
          border: `${themeBase.border.sizes.border1} solid ${themeBase.colors.brand.primary}`,
          ':hover': {
            border: `${themeBase.border.sizes.border1} solid ${themeBase.colors.brand.primary}`,
            boxShadow: `none`
          }
        }
      : {};

    const invalidStyle = state.selectProps.invalidText
      ? {
          border: `${themeBase.border.sizes.border1} solid ${themeBase.colors.feedback.errorDarken}`,
          ':hover': {
            border: `${themeBase.border.sizes.border1} solid ${themeBase.colors.feedback.errorDarken}`,
            boxShadow: `none`
          }
        }
      : {};

    const disabledStyle = state.selectProps.isDisabled
      ? {
          border: `${themeBase.border.sizes.border1} solid ${themeBase.colors.text.grayscaleLight3}`,
          boxShadow: `none`,
          '& svg': {
            color: themeBase.colors.text.grayscaleLight3
          }
        }
      : {};

    return {
      ...base,
      minHeight: '48px',
      // minWidth: 'calc(300px - 32px)',
      borderRadius: themeBase.border.radius.softlyRounded,
      border: `${themeBase.border.sizes.border1} solid ${themeBase.colors.text.grayscaleLight1}`,
      boxShadow: `none`,
      backgroundColor: 'transparent',
      ':hover': {
        border: `${themeBase.border.sizes.border1} solid ${themeBase.colors.text.grayscaleLight1}`,
        boxShadow: `none`
      },
      ...invalidStyle,
      ...focusedStyle,
      ...disabledStyle
    };
  },
  option: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'transparent'
    }
  })
};
