import styled, { css } from 'styled-components';

import { LogoProps } from '.';

const wrapperModifiers = {
  small: () => css`
    width: 40px;
    height: 40px;
  `,

  medium: () => css`
    width: 60px;
    height: 58px;
  `,

  large: () => css`
    width: 100px;
    height: 58px;
  `,

  xlarge: () => css`
    width: 180px;
    height: 58px;
  `,

  full: () => css`
    width: 280px;
    height: 58px;
  `
};

export const Wrapper = styled.div<Pick<LogoProps, 'size' | 'color'>>`
  ${({ theme, color, size }) => css`
    color: ${theme.colors.brand[color!]};

    ${!!size && wrapperModifiers[size]}
  `}
`;
