import styled, { css, DefaultTheme, keyframes } from 'styled-components';

import { CircularLoaderProps } from '.';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const spinnerModifiers = {
  sizes: {
    small: (theme: DefaultTheme) => css`
      width: 16px;
      height: 16px;
      border: 2px solid ${theme.colors.brand.white};
    `,
    medium: (theme: DefaultTheme) => css`
      width: 24px;
      height: 24px;
      border: 3px solid ${theme.colors.brand.white};
    `,
    large: (theme: DefaultTheme) => css`
      width: 32px;
      height: 32px;
      border: 3px solid ${theme.colors.brand.white};
    `,
    xlarge: (theme: DefaultTheme) => css`
      width: 40px;
      height: 40px;
      border: 4px solid ${theme.colors.brand.white};
    `
  },
  speed: {
    slow: () => css`
      animation: ${rotate} 3s linear infinite;
    `,
    medium: () => css`
      animation: ${rotate} 2s linear infinite;
    `,
    fast: () => css`
      animation: ${rotate} 1s linear infinite;
    `
  }
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Spinner = styled.div<CircularLoaderProps>`
  ${({ theme, size, speed }) => css`
    ${!!size && spinnerModifiers.sizes[size](theme)};
    ${!!speed && spinnerModifiers.speed[speed]};

    border-top-color: ${theme.colors.brand.primaryLight2};
    border-bottom-color: ${theme.colors.brand.primaryLight2};
    border-right-color: ${theme.colors.brand.primaryLight2};

    border-radius: ${theme.border.radius.circle};
  `}
`;

export const Accessibility = styled.span`
  position: absolute;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
