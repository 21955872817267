import React, { useState } from 'react';

import isFunction from 'lodash/isFunction';

import { IconButton } from '@components/IconButton';
import { Close as CloseIcon } from '@styled-icons/ionicons-outline';

import * as S from './styles';

type ChipProps = {
  label: string | React.ReactNode;
  disabled?: boolean;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Chip = ({ label, onDelete, disabled = false }: ChipProps) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleDeleteIconClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onDelete) {
      onDelete(event);
    }

    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <S.Chip
      role="button"
      aria-disabled={disabled === true}
      isDisabled={disabled}
    >
      <S.ChipLabel>{label}</S.ChipLabel>
      {isFunction(onDelete) && (
        <IconButton
          icon={<CloseIcon />}
          onClick={handleDeleteIconClick}
          disabled={disabled}
          size="small"
          title="Remover"
          withTooltip={false}
        />
      )}
    </S.Chip>
  );
};
