import { Modal } from 'react-bootstrap';

import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export const WrapperModal = styled(Modal)`
  ${({ theme }) => css`
    .modal-dialog {
      position: fixed;
      width: auto;
      margin: 0 ${theme.spacings.medium};
      pointer-events: none;
      right: 0;
      bottom: 0;

      ${down('sm')} {
        margin: 0;
      }
    }

    .modal-content {
      border-radius: ${theme.border.radius.mediumRounded};
      width: 362px;
      height: calc(100% - 32px);
      overflow-y: auto;

      ${down('sm')} {
        width: 100vw;
        height: 100vh;

        border-radius: 0;
      }
    }

    .modal-header {
      padding: ${theme.spacings.medium};
      border-bottom: none;
    }

    .btn-close {
      opacity: 0.3;
      width: 1px;
      height: 1px;
    }

    .modal-title {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.h6};
      font-weight: ${theme.font.bold};
      line-height: ${theme.font.sizes.h5};
      color: ${theme.colors.brand.secondary};
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }

    .modal-footer {
      padding: ${theme.spacings.medium};
      justify-content: space-around;
      border-top: none;

      ${down('sm')} {
        padding: ${theme.spacings.xsmall};
      }
    }
  `}
`;

// export const ModalWrapperStyles = createGlobalStyle`
//     .modal-filter {
//       opacity: 0.8 !important;
//       left: 64px !important;
//       background-color: ${({ theme }) => theme.colors.text.grayscale};

//       ${down('sm')} {
//         left: 0 !important;
//       }
//     }
//   `;
