import { api } from '@services/http';
import { parseMonthNumber, parseYearNumber } from '@utils/parseDateFormat';

const fetchFile = async (
  entityId: string,
  filename: string,
  date: Date,
  signal?: AbortSignal
): Promise<string> => {
  try {
    const month = parseMonthNumber(date);
    const year = parseYearNumber(date);

    const response = await api.post(
      `download`,
      {
        entidadeId: entityId,
        mes: month,
        ano: year,
        nomeArquivo: filename
      },
      { responseType: 'blob', signal }
    );

    const newBlob = new Blob([response.data], { type: 'application/pdf' });

    return URL.createObjectURL(newBlob);
  } catch (error) {
    throw error;
  }
};

type HandleFileReturn = {
  entityId: string;
  filename: string;
  link?: HTMLAnchorElement;
};

export const handleDownload = async (
  entityId: string,
  filename: string,
  date: Date,
  signal?: AbortSignal
): Promise<HandleFileReturn> => {
  const url = await fetchFile(entityId, filename, date, signal);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();

  return { link, filename, entityId };
};

export const handleViewer = async (
  entityId: string,
  filename: string,
  date: Date,
  signal?: AbortSignal
): Promise<HandleFileReturn> => {
  const url = await fetchFile(entityId, filename, date, signal);

  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();

  return { link, filename, entityId };
};
