import styled, { css } from 'styled-components';

export const Chip = styled.div<{ isDisabled: boolean }>`
  ${({ theme, isDisabled }) => css`
    padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
    border: 1px solid ${theme.colors.text.grayscaleLight1};
    border-radius: 24px;
    height: ${theme.spacings.large};
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacings.small};
    white-space: nowrap;
    transition: border ${theme.transition.default};
    cursor: default;
    outline: 0px;
    text-decoration: none;
    vertical-align: middle;
    box-sizing: border-box;
    color: ${theme.colors.text.grayscaleLight1};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};

    ${isDisabled
      ? css`
          border: 1px solid ${theme.colors.text.grayscaleLight3};
          color: ${theme.colors.text.grayscaleLight3};
        `
      : css`
          &:hover {
            border: 1px solid ${theme.colors.text.grayscale};
            color: ${theme.colors.text.grayscale};
          }
        `}
  `}
`;

export const ChipLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
`;
