import React, { useEffect } from 'react';

import { debounce, every, isDate, isEmpty, map, size } from 'lodash';
import { down, up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';

import { Accordion } from '@components/Accordion';
import { Alert } from '@components/Alert';
import { CardItem } from '@components/CardItem';
import { CircularLoader } from '@components/CircularLoader';
import { Divider } from '@components/Divider';
import { FiltersModal } from '@components/FiltersModal';
import { MonthCalendar } from '@components/MonthCalendar';
import {
  MultiAutocompleteInput,
  OptionType
} from '@components/MultiAutocompleteInput';
import { TextField } from '@components/TextField';
import { EntitiesProps, useFilters } from '@providers/FiltersProvider';
import { api } from '@services/http';
import Base from '@templates/Base';

import * as S from './styles';

type ResponseEntityProps = {
  id: string;
  nome: string;
};

const Invoices = () => {
  const {
    date,
    entities,
    entitiesOptions,
    files,
    loadingEntities,
    onChangeDate,
    onChangeEntities,
    onChangeEntitiesLoading,
    onChangeFilesLoading,
    onChangeEntitiesOptions,
    onChangeSearch,
    onClearSearch,
    onClientSideSearch,
    onServerSideSearch,
    searchValue
  } = useFilters();

  const isMobile = useBreakpoint(down('xl'));
  const isDesktop = useBreakpoint(up('xl'));

  /* istanbul ignore next line */
  useEffect(() => {
    const loadEntitiesOptions = async () => {
      try {
        const { data } = await api.get<ResponseEntityProps[]>(`/entidades`);

        const responseEntities: EntitiesProps[] = data.map(({ id, nome }) => ({
          label: nome,
          value: id
        }));

        onChangeEntitiesOptions(responseEntities);
        if (size(responseEntities) === 1) {
          const searchEntities = [responseEntities[0]];
          onChangeEntities(searchEntities);
          onServerSideSearch(date, searchEntities);
        } else {
          onChangeFilesLoading(false);
        }
        onChangeEntitiesLoading(false);
      } catch (error) {
        onChangeEntitiesLoading(false);
        onChangeFilesLoading(false);
      }
    };

    loadEntitiesOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = loadingEntities || files.loading;
  const filesExist =
    !isEmpty(files.data) && every(files.data, (item) => !isEmpty(item.files));

  const content = () => {
    if (loading) {
      return (
        <S.WrapperEmptyList>
          <Divider gradientTheme="menu" />
          <CircularLoader size="medium" />
        </S.WrapperEmptyList>
      );
    } else if (filesExist) {
      return (
        <S.WrapperContent>
          {map(files.data, (file) => (
            <Accordion
              key={file.id}
              type="multiple"
              items={[
                {
                  id: file.id,
                  title: file.title,
                  open: true,
                  content: (
                    <>
                      {file.id === 'carta-de-reajuste' && (
                        <S.WrapperAlert>
                          <Alert severity="info">
                            A Carta de Reajuste é o documento gerado
                            periodicamente detalhando as informações do seu
                            reajuste, conforme previsto em contrato
                          </Alert>
                        </S.WrapperAlert>
                      )}
                      <S.WrapperList>
                        {map(
                          file.files,
                          ({
                            entityName,
                            entityId,
                            extension,
                            name,
                            lastModified
                          }) => (
                            <CardItem
                              key={name}
                              name={name}
                              lastModified={lastModified}
                              extension={extension}
                              entityId={entityId}
                              entityName={entityName}
                              filterDate={date}
                            />
                          )
                        )}
                      </S.WrapperList>
                    </>
                  )
                }
              ]}
            />
          ))}
        </S.WrapperContent>
      );
    } else {
      return (
        <S.WrapperEmptyList>
          <Divider gradientTheme="menu" />
          <S.Empty>
            {files.message ||
              'Não foram encontradas faturas para este(s) Contrato(s) e período.'}
          </S.Empty>
        </S.WrapperEmptyList>
      );
    }
  };

  const handleChangeContract = (selectedOptions: OptionType[]) => {
    onChangeEntities(selectedOptions);
    onServerSideSearch(date, selectedOptions);
  };

  const handleChangePeriod = (newDate: Date) => {
    onChangeDate(newDate);
    onServerSideSearch(newDate, entities);
  };

  const debouncedSearch = debounce(
    (nextValue) => onClientSideSearch(nextValue),
    700
  );

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = event.target;
    onChangeSearch(nextValue);
    debouncedSearch(nextValue);
  };

  return (
    <Base pageTitle="Faturas">
      <S.InvoicesSection>
        <S.WrapperFilter>
          <S.FilterAlert>
            <p role="alert">
              Selecione <b>um ou mais contratos e um período</b>, para
              visualizar seu “Kit Faturamento”.
            </p>
          </S.FilterAlert>
          <S.FilterInputs>
            <MultiAutocompleteInput
              name="contracts"
              label="Contratos"
              value={entities}
              onChange={handleChangeContract}
              options={entitiesOptions}
              isRequired
              isLoading={loadingEntities}
              disabled={size(entitiesOptions) === 1}
            />

            {isDesktop && (
              <MonthCalendar
                name="period"
                label="Período"
                startDate={date}
                value={date}
                onChange={handleChangePeriod}
                isRequired
                invalidText={!isDate(date) ? 'Campo obrigatório' : ''}
              />
            )}

            {isDesktop && (
              <TextField
                type="search"
                label="Busque pelo nome ou extensão do arquivo"
                name="search"
                value={searchValue}
                onChange={handleChangeSearch}
                clearSearch={() => {
                  onClearSearch();
                  onClientSideSearch('');
                }}
              />
            )}
          </S.FilterInputs>
        </S.WrapperFilter>

        {content()}

        {isMobile && <FiltersModal />}
      </S.InvoicesSection>
    </Base>
  );
};

export default Invoices;
