import map from 'lodash/map';

import { IconButton } from '@components/IconButton';
import { useDrawer } from '@providers/DrawerProvider';
import { Close } from '@styled-icons/ionicons-outline';

import { NotificationListItem } from './NotificationListItem';
import * as S from './styles';

const notifications = [
  {
    id: 1,
    title: 'Billing',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    date: '2022-05-28T12:00:00',
    read: false
  },
  {
    id: 2,
    title: 'Data Trust',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    date: '2021-05-20T11:00:00',
    read: true
  }
];

export function NotificationDrawer() {
  const { notificationOpen, setNotificationOpen } = useDrawer();

  return (
    <S.NotificationPanel
      open={notificationOpen}
      role="menu"
      aria-hidden={!notificationOpen}
      id="notification-drawer"
    >
      <S.NotificationHeader>
        <h6>Todas as Notificações</h6>
        <IconButton
          icon={<Close />}
          onClick={setNotificationOpen}
          title="Fechar"
        />
      </S.NotificationHeader>
      <S.NotificationList>
        {map(notifications, (notification) => (
          <NotificationListItem
            key={notification.id}
            notification={notification}
          />
        ))}
      </S.NotificationList>
    </S.NotificationPanel>
  );
}
