import { useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';

import { Tooltip } from '@components/Tooltip';
import { Apps } from '@styled-icons/ionicons-outline';

import * as S from './styles';

type ApplicationMenuProps = {
  position?: Placement;
};

export const ApplicationMenu = ({
  position = 'right-end'
}: ApplicationMenuProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean | undefined>(undefined);

  const popover = (
    <S.WrapperPopover id="popover-contained">
      <S.PopoverBody>
        <span>Retornar</span>
        <S.WrapperProductLink
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_MYCLEAR_URL}`}
        >
          <S.ProductIcon>MC</S.ProductIcon>
          <span>MyClear</span>
        </S.WrapperProductLink>
      </S.PopoverBody>
    </S.WrapperPopover>
  );

  return (
    <OverlayTrigger
      rootClose
      trigger={'click'}
      placement={position}
      overlay={popover}
      onToggle={(nextShow) => setIsOpenMenu(nextShow)}
    >
      <S.Wrapper aria-label="Application Menu button">
        <Tooltip
          content="Meus produtos"
          side="right"
          open={isOpenMenu ? false : undefined}
        >
          <Apps />
        </Tooltip>
      </S.Wrapper>
    </OverlayTrigger>
  );
};
