import * as S from './styles';

export type CircularLoaderProps = {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  speed?: 'slow' | 'medium' | 'fast';
};

export const CircularLoader = ({
  size = 'medium',
  speed = 'medium'
}: CircularLoaderProps) => (
  <S.Wrapper>
    <S.Spinner size={size} speed={speed} aria-label="Spinner">
      <S.Accessibility aria-label="loading...">Loading...</S.Accessibility>
    </S.Spinner>
  </S.Wrapper>
);
