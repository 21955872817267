import { head, isEmpty, last, size, split, toUpper } from 'lodash';

export const parseInitialName = (name: string) => {
  if (isEmpty(name)) {
    return '';
  }

  const splittedName = split(name, ' ');

  const firstLetter = head(splittedName)?.charAt(0);
  const lastLetter =
    size(splittedName) > 1 ? last(splittedName)?.charAt(0) : '';

  return toUpper(`${firstLetter}${lastLetter}`);
};

export const removeExtensionName = (name: string) =>
  name.replace(/\.[^/.]+$/, '');
