import { Helmet } from 'react-helmet';

import Page404Template from '@templates/404';

export default function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>Página não encontrada | MyClear</title>
      </Helmet>
      <Page404Template />
    </>
  );
}
