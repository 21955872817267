import { CircularLoader } from '@components/CircularLoader';

import { Content } from './styles';

export default function LoadingContent() {
  return (
    <Content>
      <CircularLoader size="xlarge" />
    </Content>
  );
}
