import { Helmet } from 'react-helmet';

import InvoicesTemplate from '@templates/Invoices';

const InvoicesPage = () => {
  return (
    <>
      <Helmet>
        <title>Faturas | MyClear</title>
      </Helmet>
      <InvoicesTemplate />
    </>
  );
};

export default InvoicesPage;
