import { useState, useEffect, useReducer } from 'react';

import { drop, head, isFunction, isNil } from 'lodash';

export interface Job {
  task: () => void | Promise<void>;
}

export interface IQueue {
  addJob: (job: Job) => void;
  empty: () => void;
  onEmpty: (callback: () => void) => void;
  isExecutingTask: boolean;
}

enum ActionType {
  ADD,
  SHIFT,
  EMPTY
}

type Action = {
  type: ActionType;
  payload?: Job;
};

const jobsReducer = (state: Job[], action: Action): Job[] => {
  switch (action.type) {
    case ActionType.ADD: {
      if (!isNil(action.payload)) {
        return [...state, action.payload];
      } else {
        return state;
      }
    }
    case ActionType.SHIFT: {
      return drop(state, 1);
    }
    case ActionType.EMPTY: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const isExecutingTaskReducer = (status: boolean, action: boolean) => action;

export const useQueue = () => {
  const [state, dispatch] = useReducer(jobsReducer, []);

  const [isExecutingTask, setIsExecutingTask] = useReducer(
    isExecutingTaskReducer,
    false
  );

  const [doneCallback, setDoneCallback] = useState<() => void | null>();

  useEffect(() => {
    const func = async () => {
      if (state.length > 0 && !isExecutingTask) {
        setIsExecutingTask(true);
        const job = head(state);

        if (job) {
          await job.task();
          dispatch({ type: ActionType.SHIFT });
        }

        if (state.length === 0 && isFunction(doneCallback)) {
          doneCallback();
        }

        setIsExecutingTask(false);
      }
    };

    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, isExecutingTask]);

  const addJob = async (job: Job) => {
    dispatch({ type: ActionType.ADD, payload: job });
  };

  const empty = async () => {
    dispatch({ type: ActionType.EMPTY });
  };

  const onEmpty = (callback: () => void) => {
    setDoneCallback(callback);
  };

  return { empty, addJob, onEmpty, isExecutingTask };
};
