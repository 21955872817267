import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export const parseMonthName = (date: Date) =>
  format(date, 'MMMM', { locale: ptBR });

export const parseMonthNumber = (date: Date) => format(date, 'MM');

export const parseYearNumber = (date: Date) => format(date, 'yyyy');

export const parseFullDate = (date: Date) =>
  format(new Date(date), 'dd/MM/yyyy');
