import React from 'react';
import Select, { ActionMeta, MultiValue } from 'react-select';

import { compact, filter, isEmpty, map, size } from 'lodash';

import { Chip } from '@components/Chip';

import { Control } from './Control';
import { DropdownIndicator } from './DropdownIndicator';
import { IndicatorSeparator } from './IndicatorSeparator';
import { MultiValueRemove } from './MultiValueRemove';
import { Option } from './Option';
import * as S from './styles';
import { ValueContainer } from './ValueContainer';

export type OptionType = {
  label: string;
  value: string;
};

type MultiAutocompleteInputProps = {
  name: string;
  label: React.ReactNode | string;
  value?: OptionType[];
  options?: OptionType[];
  onChange: (selectedOptions: OptionType[]) => void;
  isRequired?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  helperText?: string;
  invalidText?: string;
  className?: string;
};

export const MultiAutocompleteInput = ({
  name,
  label,
  onChange,
  value = [],
  options = [],
  isRequired = false,
  isLoading = false,
  disabled = false,
  helperText = '',
  invalidText = '',
  className
}: MultiAutocompleteInputProps) => {
  let selectOptions = options;

  if (size(options) > 1) {
    const selectAllOption = {
      label: 'Selecionar todas',
      value: '*'
    };

    selectOptions = [selectAllOption, ...options];
  }

  const handleChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    let selectedOptions = newValue as OptionType[];

    switch (actionMeta.action) {
      case 'select-option':
        if (
          actionMeta.option?.value === '*' ||
          size(selectedOptions) === size(selectOptions) - 1
        ) {
          selectedOptions = selectOptions;
        }
        break;
      /* istanbul ignore next line */
      case 'deselect-option':
        if (actionMeta.option?.value === '*') {
          selectedOptions = [];
        } else {
          selectedOptions = filter(
            selectedOptions,
            ({ value }) => value !== '*'
          );
        }
        break;
      /* istanbul ignore next line */
      default:
        break;
    }

    onChange(selectedOptions);
  };

  const handleDelete = (removedValue: string) => {
    const newValue = filter(value, ({ value }) => value !== removedValue);
    const actionMeta = { action: 'deselect-option' } as ActionMeta<OptionType>;

    handleChange(newValue, actionMeta);
  };

  const hasChips = size(compact(options)) > 1;
  const hasAssistiveText = !isEmpty(helperText) || !isEmpty(invalidText);

  return (
    <S.MultiAutocompleteInputBase>
      <Select
        name={name}
        inputId={name}
        label={label}
        helperText={helperText}
        invalidText={invalidText}
        options={selectOptions}
        value={value}
        aria-checked={value}
        onChange={handleChange}
        isMulti
        isLoading={isLoading}
        isRequired={isRequired}
        isDisabled={disabled}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        noOptionsMessage={() => <i>Nenhuma opção encontrada</i>}
        loadingMessage={() => <i>Carregando...</i>}
        placeholder=" "
        className={className}
        components={{
          DropdownIndicator,
          IndicatorSeparator,
          MultiValueRemove,
          Option,
          ValueContainer,
          Control
        }}
        styles={S.customStylesReactSelect}
      />
      {hasChips && (
        <S.MultiAutocompleteInputChipContainer
          hasAssistiveText={hasAssistiveText}
        >
          {map(
            filter(value, ({ value }) => value !== '*'),
            (option) => (
              <Chip
                key={option.value}
                label={option.label}
                onDelete={() => handleDelete(option.value)}
              />
            )
          )}
        </S.MultiAutocompleteInputChipContainer>
      )}
    </S.MultiAutocompleteInputBase>
  );
};
