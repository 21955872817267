import { components, OptionProps } from 'react-select';

import get from 'lodash/get';

import type { OptionType } from '../';
import * as S from '../styles';

export const Option = (props: OptionProps<OptionType>) => {
  const { data, isSelected, label } = props;

  const isSelectAll = get(data, 'value') === '*';

  return (
    <div>
      <components.Option {...props}>
        <S.MultiAutocompleteInputOption>
          <S.MultiAutocompleteInputOptionCheckbox
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
          />
          <S.MultiAutocompleteInputOptionLabel isSelectAll={isSelectAll}>
            {label}
          </S.MultiAutocompleteInputOptionLabel>
        </S.MultiAutocompleteInputOption>
      </components.Option>
    </div>
  );
};
