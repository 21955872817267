import { RouteProps } from 'react-router-dom';

import HomePage from '@pages/HomePage';
import InvoicesPage from '@pages/InvoicesPage';
import OidcCallbackPage from '@pages/OidcCallbackPage';
import SignOutPage from '@pages/SignOutPage';

export interface CustomRoute extends RouteProps {
  title: string;
  pageName: string;
  private?: boolean;
}

export const configRoute: Array<CustomRoute> = [
  {
    title: 'Login',
    path: '/',
    pageName: 'Home',
    component: HomePage
  },
  {
    title: 'Logout',
    path: '/api/auth/logout',
    pageName: 'SignOut',
    component: SignOutPage
  },
  {
    title: 'OIDC Callback',
    path: '/api/auth/callback/clearsaleSSO',
    pageName: 'OidcCallback',
    component: OidcCallbackPage
  },
  {
    title: 'Faturas',
    path: '/faturas',
    pageName: 'Invoices',
    component: InvoicesPage,
    private: true
  }
];
