import { useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import { useHistory } from 'react-router-dom';

import { Avatar } from '@components/Avatar';
import { Divider } from '@components/Divider';
import { Tooltip } from '@components/Tooltip';
import { useAuth } from '@providers/AuthProvider';
import { useFilters } from '@providers/FiltersProvider';
import { Person, LogOut, Receipt } from '@styled-icons/ionicons-outline';

import * as S from './styles';

type UserMenuProps = {
  position?: Placement;
};

export const UserMenu = ({ position = 'right-end' }: UserMenuProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean | undefined>(undefined);
  const { currentUser } = useAuth();
  const history = useHistory();
  const { entitiesOptions, loadingEntities } = useFilters();

  const popover = (
    <S.WrapperPopover id="popover-contained">
      <S.PopoverBody>
        <S.PopoverUser>
          <Avatar
            name={currentUser.profile.name ?? ''}
            size="large"
            photo={currentUser.profile.picture}
          />

          <S.PopoverWrapperInfo>
            <S.PopoverUserName aria-label="Username">
              {currentUser.profile.name}
            </S.PopoverUserName>
            <span>{!loadingEntities && entitiesOptions[0]?.label}</span>
          </S.PopoverWrapperInfo>
        </S.PopoverUser>

        <Divider gradientTheme="menu" />

        <S.PopoverButtonItens
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_MYCLEAR_URL}/minha-conta`}
        >
          <Person />
          <span>Minha Conta</span>
        </S.PopoverButtonItens>

        <Divider gradientTheme="menu" />

        <S.PopoverGroupItens>
          <S.PopoverButtonItens
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_MYCLEAR_URL}/termos`}
          >
            <Receipt />
            <span>Termos e Políticas</span>
          </S.PopoverButtonItens>
        </S.PopoverGroupItens>

        <Divider gradientTheme="menu" style={{ margin: '10px 0' }} />

        <S.PopoverButtonItens onClick={() => history.push('/api/auth/logout')}>
          <LogOut />
          <span>Sair</span>
        </S.PopoverButtonItens>
      </S.PopoverBody>
    </S.WrapperPopover>
  );

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement={position}
      overlay={popover}
      onToggle={(nextShow) => setIsOpenMenu(nextShow)}
    >
      <S.Wrapper aria-label="User Menu button">
        <Tooltip
          content="Menu"
          side="right"
          open={isOpenMenu ? false : undefined}
        >
          <Avatar
            name={currentUser.profile.name ?? ''}
            size="small"
            photo={currentUser.profile.picture}
          />
        </Tooltip>
      </S.Wrapper>
    </OverlayTrigger>
  );
};
