import { createContext, useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { get, includes, isEmpty, isNil, toString } from 'lodash';
import Cookies from 'universal-cookie';

import { api } from '@services/http';
import LoadingContent from '@templates/LoadingContent';
import { AuthCookiesKey } from '@utils/constants/AuthCookiesKey';
import { StatusCode } from '@utils/constants/StatusCode';

interface AuthUserType {
  profile: {
    name: string;
    email: string;
    picture?: string;
  };
  sub: string;
  expires?: Date;
}

interface AuthStateType {
  loading: boolean;
  redirectUri?: string;
}

type UserInfoResponse = {
  sub: string;
  email: string;
  emailVerified: string;
  entity: string;
  name: string;
};

type LogoutResponse = {
  logoutUrl: string;
};

export type AuthProviderType = {
  currentUser: AuthUserType;
  state: AuthStateType;
  isAuthenticated: () => boolean;
  signInCallback: () => Promise<void>;
  signOut: () => Promise<void>;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext<AuthProviderType>(
  {} as AuthProviderType
);

const notLoadUserList = ['/api/auth/callback/clearsaleSSO', '/api/auth/logout'];

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, setState] = useState<AuthStateType>({
    loading: true,
    redirectUri: ''
  });
  const [currentUser, setCurrentUser] = useState<AuthUserType>(
    {} as AuthUserType
  );

  const history = useHistory();

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUserInfo(expires: Date) {
    const cookies = new Cookies();
    const cookieSessionId = cookies.get(AuthCookiesKey.AUTH_SESSION_ID);

    const { data: userInfo } = await api.get<UserInfoResponse>(
      'auth/user-info',
      {
        headers: { sessid: cookieSessionId }
      }
    );

    setCurrentUser({
      profile: {
        name: userInfo.name,
        email: userInfo.email,
        picture: ''
      },
      sub: userInfo.sub,
      expires
    });
  }

  async function loadUser() {
    const { location } = history;
    const cookies = new Cookies();

    if (!includes(notLoadUserList, location.pathname)) {
      if (isEmpty(currentUser)) {
        const expires = new Date();
        expires.setHours(expires.getHours() + 8);

        try {
          await getUserInfo(expires);

          if (location.pathname === '/') {
            history.push('/faturas');
          }
        } catch (responseError) {
          const headers = get(responseError, 'response.headers');
          const status = get(responseError, 'response.status');

          if (headers && status === StatusCode.REDIRECT) {
            let redirectUrl = toString(get(headers, 'redirecturl'));
            const sessionId = get(headers, 'sessid');

            cookies.set(AuthCookiesKey.AUTH_SESSION_ID, sessionId, {
              expires,
              secure: true
            });

            if (process.env.NODE_ENV === 'development') {
              redirectUrl = redirectUrl.replace(
                new RegExp('\\https://devfinanceiro.clear.sale'),
                'http://localhost:3000'
              );
            }

            window.location.replace(redirectUrl);
          } else if (status === StatusCode.UNAUTHORIZED) {
            /**
             * TODO
             * Quando tiver implementado o refresh token no backed,
             * fazer o refresh e redirecionar para a página de faturas
             */

            cookies.remove(AuthCookiesKey.AUTH_SESSION_ID);
            window.location.replace('/');
          } else {
            cookies.remove(AuthCookiesKey.AUTH_SESSION_ID);
            window.location.replace('/');
          }
        }
      }
    }

    setState({
      loading: false,
      redirectUri: ''
    });
  }

  const isAuthenticated = (): boolean => {
    if (isEmpty(currentUser)) {
      return false;
    }

    const { expires } = currentUser;

    if (!isNil(expires)) {
      return new Date() < expires;
    } else {
      return false;
    }
  };

  async function signInCallback() {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('code');

      if (!isEmpty(code)) {
        const { status, data } = await api.get<string>('auth/oidc-callback', {
          params: { code }
        });

        const cookies = new Cookies();

        // se retornar status 200, deu certo o login e o retorno
        // após é salvo o novo session id no cookie e é
        // redirecionado para a página de faturas
        // senão é redirecionado para o logout para começar o processo novamente
        if (status === StatusCode.OK) {
          const expires = new Date();
          expires.setHours(expires.getHours() + 8);

          cookies.set(AuthCookiesKey.AUTH_SESSION_ID, data, {
            expires,
            secure: true
          });

          await getUserInfo(expires);

          history.replace('/faturas');
        }
      }
    } catch (error: any) {
      throw new Error(JSON.stringify(error));
    }
  }

  async function signOut() {
    try {
      const { data } = await api.post<LogoutResponse>('auth/logout');

      setCurrentUser({} as AuthUserType);

      const cookies = new Cookies();
      cookies.remove(AuthCookiesKey.AUTH_SESSION_ID);

      let logoutUrl = data.logoutUrl;

      if (process.env.NODE_ENV === 'development') {
        logoutUrl = logoutUrl.replace(
          new RegExp('\\https://devfinanceiro.clear.sale'),
          'http://localhost:3000'
        );
      }

      window.location.replace(logoutUrl);
    } catch (responseError: any) {
      const { headers, status } = responseError;
      if (headers && status === StatusCode.REDIRECT) {
        const redirectUrl = get(headers, 'redirecturl');
        window.location.replace(redirectUrl);
      } else if (status === StatusCode.UNAUTHORIZED) {
        /**
         * TODO
         * Quando tiver implementado o refresh token no backed,
         * fazer o refresh e redirecionar para a página de faturas
         */
        window.location.replace('/');
      } else {
        window.location.replace('/');
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAuthenticated,
        state,
        signInCallback,
        signOut
      }}
    >
      {state.loading ? <LoadingContent /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

type ProtectedRouteProps = {
  children: JSX.Element;
};

export const ProtectedRoute = ({
  children
}: ProtectedRouteProps): JSX.Element => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return children;
  }

  return <Redirect to="/" />;
};
